import menu from './Menu';
import cart from './Cart';
import customer from './Customer';
import ordering from './Ordering';
import deals from './Deals';
import home from './Home';
import more from './More';

export default {
    menu,
    cart,
    customer,
    ordering,
    deals,
    home,
    more
}
