import React from 'react';
import {Slider} from "../../uiComponents";
import {Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import { useHistory } from "react-router-dom";

function Home({homeBanners, deals, ordering}) {
    const history = useHistory();

    function navigateToDeal(obj) {
        if(obj.route) {
            if(obj.requesturl){
                history.push(`deal/${obj.route}`);
            } else {
                history.push(`/${obj.route}`);
            }
        }
    }

    function renderDeals(array) {
        if(array){
          return deals.map((object, index) => {
                return(
                    <a onClick={() => navigateToDeal(object)} key={index}>
                       <Image src={object.imageurl}/>
                    </a>
                )
            })
        }else {
            return null;
        }
    };

    return (
        <div className="home">
            <Slider slides={homeBanners}/>

            <div className="select-preference">
                {ordering && ordering[0] ? <Link to='/pizzas'>
                    <Image src={ordering[0].imageurl}/>
                </Link> : null}
                {ordering && ordering[1] ? <Link to='/quickordering'>
                    <Image src={ordering[1].imageurl}/>
                </Link> : null}
            </div>

            <div className="deals-section">
                {renderDeals(deals)}
            </div>

        </div>
    );
}
const mapStateToProps = ({home}) => {
    return {
        homeBanners: home.homeBanners,
        deals: home.deals,
        ordering: home.ordering
    }
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
