import React from 'react';

function Chat() {
    return (
        <div className="chat">
            <p>Chat</p>
        </div>
    );
}

export default Chat;
