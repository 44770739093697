import {
    GET_CUSTOMER_AND_OTHER_INFORMATION,
    CALCULATE_DISCOUNT_AND_TAX,
    UPDATE_APP_VIEW,
    GET_CUSTOMER_DETAIL_WITH_ID,
    GET_PREVIOUS_ADDRESSES_WITH_ID,
    UPDATE_PLATFORM, APPLY_PROMO_CODE
} from '../constants/Customer';
import { get, post } from "../helper";

export const getCustomerInformation = () => (dispatch) => {
    let payload = {
        ordertypes: [],
        paymentmethods: [],
        discountpercentage: [],
        cities: [],
        customerdetails: [],
        textinformation: {},
        loading: true
    };
    dispatch({type: GET_CUSTOMER_AND_OTHER_INFORMATION, payload});
    get('customerinformation')
        .then((response) => {
            if(response && response.data && response.data.customerinformation && response.data.customerinformation[0]){
                let {
                    ordertypes,
                    paymentmethods,
                    discountpercentage,
                    cities,
                    customerdetails,
                    textinformation
                } = response.data.customerinformation[0];
                payload.ordertypes = (ordertypes && ordertypes.length) ? ordertypes: [];
                payload.paymentmethods = (paymentmethods && paymentmethods.length) ? paymentmethods: [];
                payload.discountpercentage = (discountpercentage && discountpercentage.length) ? discountpercentage: [];
                payload.cities = (cities && cities.length) ? cities: [];
                payload.customerdetails = (customerdetails && customerdetails.length) ? customerdetails: [];
                payload.textinformation = (textinformation && Object.keys(textinformation).length) ? textinformation: [];
                payload.loading = false;
                dispatch({type: GET_CUSTOMER_AND_OTHER_INFORMATION, payload});
            } else {
                payload.loading = false;
                dispatch({type: GET_CUSTOMER_AND_OTHER_INFORMATION, payload});
            }
        })
        .catch((error) => {
            payload.loading = false;
            dispatch({type: GET_CUSTOMER_AND_OTHER_INFORMATION, payload});
        });
};

export const calculateDiscountAndTax = (payload) => (dispatch) => {
    if(payload && payload.length){
        console.log(payload)
        dispatch({type: CALCULATE_DISCOUNT_AND_TAX, payload: {loading: true}});
        get('calculatediscountandtax', payload)
            .then((response) => {
                const { data } = response;
                dispatch({type: CALCULATE_DISCOUNT_AND_TAX, payload: {
                        loading: false,
                        calculations: (data && data.calculations && data.calculations.length) ? data.calculations[0] : {}
                    }});
            })
            .catch((error) => {
                dispatch({type: CALCULATE_DISCOUNT_AND_TAX, payload: {loading: false}})
            });
    }
};

export const checkAppView = (value) => (dispatch) => {
    dispatch({type: UPDATE_APP_VIEW, appView: value});
};
export const updatePlatform = (value) => (dispatch) => {
    dispatch({type: UPDATE_PLATFORM, platform: value});
};

export const getCustomerDetailWithId = (payload) => (dispatch) => {
    dispatch({type: GET_CUSTOMER_DETAIL_WITH_ID, payload: {loading: true}});
    post('customerdetails', payload)
        .then((response) => {
            const { data } = response;
            dispatch({type: GET_CUSTOMER_DETAIL_WITH_ID, payload: {
                    user: (data && data.length && data[0]) ? data[0] : {},
                    loading: false,
                }});
        })
        .catch((error) => {
            console.log('getCustomerDetailWithId error', error)
            dispatch({type: GET_CUSTOMER_DETAIL_WITH_ID, payload: {loading: false}})
        });
};
export const getPreviousAddressesWithId = (payload) => (dispatch) => {
    dispatch({type: GET_PREVIOUS_ADDRESSES_WITH_ID, payload: {loading: true}});
    post('viewaddresses', payload)
        .then((response) => {
            const { data } = response;
            dispatch({type: GET_PREVIOUS_ADDRESSES_WITH_ID, payload: {
                    addresses: data,
                    loading: false,
                }});
        })
        .catch((error) => {
            console.log('getPreviousAddressesWithId error', error);
            dispatch({type: GET_PREVIOUS_ADDRESSES_WITH_ID, payload: {loading: false}})
        });
};

export const applyPromoCode = (payload, CB) => (dispatch) => {
    // https://www.fspcmobile.com/webservices/applypromocode
    dispatch({type: APPLY_PROMO_CODE, loading: true });
    post('applypromocode', payload)
        .then((response) => {
            const { data } = response;
            CB && CB(data, payload.promocode);
            dispatch({type: APPLY_PROMO_CODE, loading: false });
        })
        .catch((error) => {
            dispatch({type: APPLY_PROMO_CODE, loading: false });
        });
}
