import React, {Component, Fragment} from 'react';
import {Banner, Section} from "../../uiComponents";
import {connect} from "react-redux";
import {addToCart} from "../../store/actions/Cart";
import {Button} from "react-bootstrap";
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';

class QuickOrdering extends Component {

    constructor(props) {
        super(props);
        this.state = {
            quantity: 1,
            crust: {},
            size: {},
            flavour: {},
        }
    }

    clearState = () => {
        this.setState({
            quantity: 1,
            crust: {},
            size: {},
            flavour: {},
        })
    }

    increment = () => {
        this.setState({quantity: this.state.quantity + 1})
    };
    decrement = () => {
        if (this.state.quantity >= !0) this.setState({quantity: this.state.quantity - 1})
    };

    onSelect = (type, selected) => {
        this.setState({[type]: selected})
    };

    setData = (pizzacrusts, pizzasizes, pizzaflavors) => {

        let crust = pizzacrusts ? pizzacrusts[0] : {};
        let size = crust && pizzasizes[crust.crustId] ? pizzasizes[crust.crustId][0] : {};
        let flavour = crust && size && pizzaflavors[crust.crustId][size.sizeId] ? pizzaflavors[crust.crustId][size.sizeId][0] : {};

        this.setState({crust, size, flavour})
    };

    componentWillReceiveProps(nextProps) {
        const {pizzacrusts, pizzasizes, pizzaflavors} = nextProps;
        if (nextProps) this.setData(pizzacrusts, pizzasizes, pizzaflavors)
    }

    addToCart = () => {
        let {crust, size, flavour, quantity} = this.state;
        let { addToCart } = this.props;
        let payload = {
            name: {
                pizzatype: 'pizza',
                pizzacrust: crust.hiddename,
                pizzameatallowed: 1,
                pizzasize: size.size,
                pizzaflavor: flavour.flavorsauce,
                meattopping1: flavour.flavor,
                meattopping2: '',
                extrameattopping1: '',
                extrameattopping2: '',
                extracheese: '',
                vegetables1: flavour.flavorvegetables,
                vegetables2: '',
                sweettoppings: '',
                pizzaquantity: quantity,
            },
            producttype: 'pizza',
            quantity: quantity,
            price: size.price,
            pizzaextrameatprice: 0,
            pizzaextracheeseprice: 0,
            pizzasweettoppingsprice: 0,
            total: size.price * quantity
        };
        addToCart(payload);
        this.clearState()
    };

    render() {
        const {quantity, size, crust, flavour} = this.state;
        const {pizzacrusts, pizzasizes, pizzaflavors} = this.props;
        let selectedPizzaSizes = (pizzasizes && crust && crust.crustId) ? pizzasizes[crust.crustId] : [];
        let selectedPizzaFlavorsWithCrust = (pizzaflavors && crust && crust.crustId) ? pizzaflavors[crust.crustId] : [];
        let selectedPizzaFlavors = (selectedPizzaFlavorsWithCrust && size && size.sizeId) ? selectedPizzaFlavorsWithCrust[size.sizeId] : [];
        let disabled = !(Object.keys(size).length && Object.keys(crust).length && Object.keys(flavour).length && quantity !== 0);
        return (
            <Fragment>
                <div className="inner-body-with-no-scroll home">
                    {/*<Banner image='/images/banner.png'/>*/}
                    <h4>quick ordering</h4>

                    {(pizzacrusts && pizzacrusts.length) ? <Section
                        title="Select Your crust"
                        data={pizzacrusts}
                        titleKey='displayname'
                        idKey='crustId'
                        selectedIdKey='crustId'
                        selected={crust}
                        onSelect={selected => this.onSelect('crust', selected)}
                    /> : null}

                    {(selectedPizzaSizes && selectedPizzaSizes.length) ? <Section
                        title="Select Your size"
                        data={selectedPizzaSizes}
                        titleKey='size'
                        idKey='sizeId'
                        priceKey='price'
                        servingsizeKey='servingsize'
                        selectedIdKey='sizeId'
                        selected={size}
                        onSelect={selected => this.onSelect('size', selected)}
                    /> : null}

                    {(selectedPizzaFlavors && selectedPizzaFlavors.length) ? <Section
                        title="Select Your Flavour"
                        titleKey='flavor'
                        idKey='flavor'
                        imageKey='flavorimage'
                        selectedIdKey='flavor'
                        data={selectedPizzaFlavors}
                        selected={flavour}
                        onSelect={selected => this.onSelect('flavour', selected)}
                    /> : null}

                </div>
                <div className="common-footer">
                    <div className="counter">
                        <button className="minus" onClick={() => this.decrement()}>
                            <MinusOutlined/>
                        </button>
                        <span className="counter-value">{quantity}</span>
                        <button className="plus" onClick={() => this.increment()}>
                            <PlusOutlined/>
                        </button>
                    </div>
                    <Button onClick={() => this.addToCart()} className="c-btn" disabled={disabled}>
                        Add to cart
                    </Button>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ordering}) => {
    return {
        pizzacrusts: ordering.quickPizzaOrdering.pizzacrusts,
        pizzasizes: ordering.quickPizzaOrdering.pizzasizes,
        pizzaflavors: ordering.quickPizzaOrdering.pizzaflavors,
    }
};
const mapDispatchToProps = {
    addToCart
};
export default connect(mapStateToProps, mapDispatchToProps)(QuickOrdering);
