import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {interceptor} from "./store/helper";
import {persistor, store} from "./store";
import {Provider} from "react-redux";
import { PersistGate } from 'redux-persist/es/integration/react';

interceptor();

const Pizza = () => {
    return(
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    )
};

ReactDOM.render(<Pizza />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
