import React, {Fragment, useEffect} from 'react';
import {Image} from "react-bootstrap";
import {MdMenu, MdShoppingCart, MdArrowBack} from "react-icons/md";
import {Link, useHistory} from "react-router-dom";
import {connect} from 'react-redux';
import {Alert} from '../index';

function Header({back = false, cart = false, menu = false, counter, discount, textinformation, appView}) {
    const history = useHistory();

    useEffect(() => {
        if(window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage(window.location.href)
        }
    })

    window.goToCart = function() {
        // Log to the console
        navigateOrderPage();
    };

    function navigateOrderPage() {
        history.push('/yourorder');
    }
    function goBack() {
        history.goBack();
    }

    return appView ? (<Fragment>
                {(textinformation && textinformation.headertext) ? <Alert
                    // text={`Hey visitor, We've got a ${discount.discountpercentage}% discount for you! Now thats Awesome!`}
                    text={textinformation.headertext}
                /> : null}
                <div className="header">
                    {back ? <button onClick={()=> goBack()}>
                        <MdArrowBack/>
                    </button> : null}
                    <div className={`logo-container ${back ? 'center' : ''}`}>
                        <Link to="/">
                            <Image src={require('../../assets/images/logo.png')}/>
                        </Link>
                    </div>
                    {cart ? <button disabled={!(counter)}
                                    className="cart-button"
                                    onClick={navigateOrderPage}>
                        <span>{counter}</span>
                        <MdShoppingCart/>
                    </button> : null}

                    {menu ? <button>
                        <MdMenu/>
                    </button> : null}
                </div>
            </Fragment>) : null;
}

const mapStateToProps = ({cart, customer}) => {
    const {ordereditems} = cart;
    return {
        appView: customer.appView,
        counter: (ordereditems && ordereditems.length) ? ordereditems.length : 0,
        discount: (customer.discountpercentage && customer.discountpercentage[0]) ? customer.discountpercentage[0] : null,
        textinformation: (customer.textinformation) ? customer.textinformation : null
    }
};
export default connect(mapStateToProps, null)(Header);
