import React from 'react';
import {ListItem, Slider} from "../../uiComponents";
import {Tab, Tabs} from "react-bootstrap";
import {connect} from "react-redux";
import { Empty } from 'antd';
import { useHistory } from "react-router-dom";

function renderTabs(dealTypes, dealTypesDetails, history) {

    function navigateToDeal(deal) {
        history.push(`/deal/${deal.requesturl}`, {
            ...deal
        });
    }

    if (!dealTypes) return null;
    return dealTypes.map((dealType, index) => {
        if(dealTypesDetails && dealTypesDetails[dealType ? dealType.dealtypeId : '']){
            return (
                <Tab key={index} eventKey={`${dealType.dealtypeId}`} title={dealType.name}>
                    {dealTypesDetails[dealType.dealtypeId] ?
                        <ListItem
                            type="deal"
                            titleKey="name"
                            imageKey="imageformobile"
                            priceKey="price"
                            onClick={(selectedDeal) => navigateToDeal(selectedDeal)}
                            data={dealTypesDetails[dealType.dealtypeId]}/> :
                        <div className="empty-container">
                            <Empty/>
                        </div>}
                </Tab>
            )
        }
        return null
    });
}

function Deals(props) {
    const history = useHistory();
    const {dealTypes, dealTypesDetails, dealBanners} = props;

    return (
        <div className="deals">
            <Slider
                className="deals-slider"
                slides={dealBanners}/>
            <Tabs className="c-tab" id="uncontrolled-tab-example">
                {renderTabs(dealTypes, dealTypesDetails, history)}
            </Tabs>
        </div>
    );
}

const mapStateToProps = ({deals}) => {
    return {
        dealBanners: deals.dealbanners,
        dealTypes: deals.dealtypes,
        dealTypesDetails: deals.dealtypesdetails
    }
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Deals);
