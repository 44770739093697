import React from 'react';
import {ListItem} from "../../uiComponents";
import { useHistory } from "react-router-dom";
import {connect} from "react-redux";
import { Switch, Route, NavLink } from "react-router-dom";
import {slugify} from "../../store/helper";

const Nav = ({location}) => {
    return  <div className="c-tab c-nav">
        <NavLink className={(location && location.pathname === '/menu' ? 'active' : '')}
                 activeClassName='active' to={`/pizzas`}>
            CREATE YOUR OWN PIZZA
        </NavLink>
        <NavLink activeClassName='active' to={`/sidelines`}>
            SIDELINES
        </NavLink>
        <NavLink activeClassName='active' to={`/drinks`}>
            DRINKS
        </NavLink>
        <NavLink activeClassName='active' to={`/sweetsomethings`}>
            SWEET SOMETHINGS
        </NavLink>
        <NavLink activeClassName='active' to={`/dips`}>
            EXTRA DIPS
        </NavLink>
    </div>
}

function Menu(props) {

    const history = useHistory();

    function createOwnPizza(obj) {
        const { pizzaOrdering } = props;
        let slug = slugify(obj.displayname);
        history.push(`/pizza/${slug}`, {
            crust: obj,
            size: pizzaOrdering.pizzasizes[obj.crustId] ? pizzaOrdering.pizzasizes[obj.crustId] : [],
            flavors: pizzaOrdering.pizzaflavors ? pizzaOrdering.pizzaflavors[obj.crustId] : []
        });
    }

    function addToCart(selected){
        if(selected.variations) {
            history.push('variations', selected);
        } else {
            // console.log('selected', selected)
        }
    }

    const Pizzas = () => <ListItem className="card"
                                   data={props.pizzaOrdering.pizzacrusts}
                                   titleKey="displayname"
                                   onClick={(obj) => createOwnPizza(obj)}/>;
    const Sidelines = () => <ListItem type="menu"
                                   titleKey="mainname"
                                   priceKey="startingprice"
                                   data={props.slideLines}
                                   onClick={(obj) => addToCart(obj)}/>;
    const Drinks = () => <ListItem type="menu"
                                   titleKey="mainname"
                                   priceKey="startingprice"
                                   data={props.drinks}
                                   onClick={(obj) => addToCart(obj)}/>;
    const SweetSomethings = () => <ListItem type="menu"
                                            titleKey="mainname"
                                            priceKey="startingprice"
                                            data={props.sweetSomeThings}
                                            onClick={(obj) => addToCart(obj)}/>;
    const Dips = () => <ListItem type="menu"
                                 data={props.dips}
                                 titleKey="mainname"
                                 priceKey="startingprice"
                                 onClick={(obj) => addToCart(obj)}/>;

    return (
        <div className="menu">
            <Nav {...props}/>
           <div className="tab-content">
               <Switch>
                   <Route path={`/menu`} component={Pizzas}/>
                   <Route path={`/pizzas`} component={Pizzas}/>
                   <Route path={`/sidelines`} component={Sidelines}/>
                   <Route path={`/drinks`} component={Drinks}/>
                   <Route path={`/sweetsomethings`} component={SweetSomethings}/>
                   <Route path={`/dips`} component={Dips} />
               </Switch>
           </div>
        </div>
    );
}

// menu.sweetSomeThings
const mapStateToProps = ({ menu, ordering }) => {
    return {
        sweetSomeThings: menu.sweetSomeThings,
        drinks: menu.drinks,
        slideLines: menu.slideLines,
        dips: menu.dips,
        pizzaOrdering: ordering.pizzaOrdering
    }
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
