import React from "react";
import {Tabs, Header} from "..";

const returnPathName = (pathname) => {
    if(pathname === '/sidelines'){
        return '/menu'
    } else if(pathname === '/drinks'){
        return '/menu'
    } else if(pathname === '/pizzas'){
        return '/menu'
    } else if(pathname === '/sweetsomethings'){
        return '/menu'
    } else if(pathname === '/dips'){
        return '/menu'
    } else {
        return pathname
    }
}

export const TabWithContainer = (Element, cart = true, menu = false) => props => {
    return (
        <div className="tab-with-container">
            <Header cart={cart} menu={menu}/>
           <div className='inner-container'>
               <Element {...props} />
           </div>
            <Tabs activeRoute={(props && props.location ? returnPathName(props.location.pathname) : '')}/>
        </div>
    )
};
