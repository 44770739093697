import React from 'react';
import {Button, Image} from "react-bootstrap";
import {useHistory} from "react-router-dom";

function NotFound() {
    const history = useHistory();

    function goBack() {
        history.push('/');
    }

    return (
        <div className="not-found">
            <div className="icon">
                <Image src={require('../../assets/images/notfound.svg')}/>
            </div>
            <p>Not Found</p>
            <Button className="c-btn" onClick={()=> goBack()}>
                Go Back
            </Button>
        </div>
    );
}

export default NotFound;
