import {post, alert} from "../helper";
import {SUBMIT_FEEDBACK, SUBMIT_FRANCHISE_QUERY} from "../constants/More";


export const submitFranchiseQuery = (payload, CB) => (dispatch) => {
    dispatch({type: SUBMIT_FRANCHISE_QUERY, loading: true});
    post('postfranchisingform', payload)
        .then((response) => {
            const { data } = response;
            if(data && data.status === 'success'){
                alert(data && data.message);
                CB()
            } else {
                alert('Something went wrong please try again later', 'error')
            }
            dispatch({type: SUBMIT_FRANCHISE_QUERY, loading: false});
        })
        .catch((error) => {
            dispatch({type: SUBMIT_FRANCHISE_QUERY, loading: false})
        });
};
export const submitFeedback = (payload, CB) => (dispatch) => {
    dispatch({type: SUBMIT_FEEDBACK, loading: true});
    post('postfeedbackform', payload)
        .then((response) => {
            const { data } = response;
            if(data && data.status === 'success'){
                alert(data && data.message)
                CB()
            } else {
                alert('Something went wrong please try again later', 'error')
            }
            dispatch({type: SUBMIT_FEEDBACK, loading: false});
        })
        .catch((error) => {
            dispatch({type: SUBMIT_FEEDBACK, loading: false})
        });
};
