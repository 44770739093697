import {SUBMIT_FEEDBACK, SUBMIT_FRANCHISE_QUERY} from "../constants/More";

const initialState = {
    loading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SUBMIT_FRANCHISE_QUERY:
            return { ...state, loading: action.loading };
        case SUBMIT_FEEDBACK:
            return { ...state, loading: action.loading };
        default:
            return state;
    }
};
