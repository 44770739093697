import React, {Component, Fragment} from 'react';
import {Button, Image} from "react-bootstrap";
import {connect} from "react-redux";
import {addToCart} from "../../store/actions/Cart";
import decode from "decode-html";
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';

class Variations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedVariation: null,
            quantity: 1,
            isVisible: true
        }
    }

    setVariation = (selectedVariation) => this.setState({selectedVariation});

    renderVariations = (variations) => {
        const {selectedVariation} = this.state;
        if (variations) {
            return variations.map((variation, index) => {
                let activeClass = (selectedVariation && selectedVariation[':name'] === variation[':name'] ? 'active' : '');
                return (
                    <div className="list-item" key={index} onClick={() => this.setVariation(variation)}>
                        <span className={`icon ${activeClass}`}/>
                        <span>{variation[':displayname']}</span>
                        <span>Rs.{variation[':price']}</span>
                    </div>
                )
            })
        }
        return null
    };

    addCart = () => {
        const {selectedVariation, quantity} = this.state;
        const {location, addToCart} = this.props;
        const {state} = location;
        let total = Number(selectedVariation[':price']) * quantity;
        let payload = {
            producttype: state.type,
            name: selectedVariation[':name'],
            price: selectedVariation[':price'],
            quantity: quantity.toString(),
            total: total.toString(),
        }
        this.setState({selectedVariation: null, quantity: 1});
        addToCart(payload)
    };

    increment = () => this.setState({quantity: this.state.quantity + 1});
    decrement = () => (this.state.quantity >= !0) && this.setState({quantity: this.state.quantity - 1});

    render() {
        const {selectedVariation, quantity} = this.state;
        const {location} = this.props;
        const {state} = location;
        let image = (state && state.image) ? state.image : '/images/placeholder.png';
        let disabled = (state && state.variations) ? !((selectedVariation) && quantity !== 0) : !(quantity !== 0)
        return (
            <Fragment>
                <div className="inner-body-with-no-scroll variations">
                    <div className="variations-container">
                        <div className="variations-card">
                            <div className="variations-header">
                                <Image src={image}/>

                                {state && state.mainname ? <h1>{state.mainname}</h1> : null}
                                {state && state.description ?
                                    <p dangerouslySetInnerHTML={{__html: decode(state.description)}}/> : null}
                            </div>
                            <div className="variations-body">
                                <div className="list">
                                    <div className="list-header">
                                        <div className="list-header-left">
                                            <span> Variation </span>
                                            <span> Select One </span>
                                        </div>
                                        <span>1 REQUIRED</span>
                                    </div>
                                    {this.renderVariations(state && state.variations)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="common-footer">
                    <div className="counter">
                        <button className="minus" onClick={() => this.decrement()}>
                            <MinusOutlined/>
                        </button>
                        <span className="counter-value">{quantity}</span>
                        <button className="plus" onClick={() => this.increment()}>
                            <PlusOutlined/>
                        </button>
                    </div>
                    <Button onClick={() => this.addCart()} className="c-btn" disabled={disabled}>
                        Add to cart
                    </Button>
                </div>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    addToCart
};
export default connect(null, mapDispatchToProps)(Variations);
