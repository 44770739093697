import {
    ADD_ITEM_TO_CART,
    REMOVE_ITEM_TO_CART,
    PROCEED_TO_CHECK_OUT, PROCEED_TO_CHECK_OUT_SUCCESS
} from '../constants/Cart';
import {post, alert} from "../helper";
import { message } from 'antd';
message.config({
    top:70,
    maxCount: 1,
});

const renderOrderName = (order) => {
    if (order && order.name && order.name.pizzatype) {
        return `${order.name.pizzacrust} ${order.name.pizzasize} ${order.name.meattopping1}`
    } if (order && order.pizzatype) {
        return `${order.pizzacrust} ${order.pizzasize} ${order.meattopping1}`
    } else {
        return order.name;
    }
};
export const addToCart = (item) => (dispatch) => {
    let name = renderOrderName(item);
    console.log("Item Added",item);
    alert(`Your order ${name} successfully added in your cart.`);
    dispatch({type: ADD_ITEM_TO_CART, item })
};
export const removeToCart = (item, orders, CB) => (dispatch) => {
    let name = renderOrderName(item);
    alert(`Your order ${name} successfully removed in your cart.`, 'info');
    dispatch({type: REMOVE_ITEM_TO_CART, orders })
    CB();
};

export const checkout = (payload, CB) => (dispatch) => {
    dispatch({type: PROCEED_TO_CHECK_OUT, loading: true});
    post('https://m.14thstreetpizza.com/processorder', payload)
        .then((response) => {
            const { data } = response;
            dispatch({type: PROCEED_TO_CHECK_OUT_SUCCESS, loading: false});
            if(data && data.responseurl) window.location = data.responseurl;
        })
        .catch((error) => {
            dispatch({type: PROCEED_TO_CHECK_OUT, loading: false})
        });
};
