import {Link} from "react-router-dom";
import React from "react";
import { MdHome } from 'react-icons/md';
import { IoMdList, IoMdPricetag, IoMdMore } from 'react-icons/io';
import { AiOutlineMessage } from 'react-icons/ai';

const tabs = [
    {
        title: 'Home',
        icon: () => <MdHome/>,
        path: '/'
    },
    {
        title: 'Menu',
        icon: () => <IoMdList/>,
        path: '/menu'
    },
    {
        title: 'Deals',
        icon: () => <IoMdPricetag/>,
        path: '/deals'
    },
    {
        title: 'Chat',
        icon: () => <AiOutlineMessage/>,
        path: '/chat'
    },
    {
        title: 'More',
        icon: () => <IoMdMore/>,
        path: '/more'
    }
];

export const Tabs = ({activeRoute = ''}) => {
    return (
        <div className="tabs">
            {tabs.map((tab, index) => {
                if(tab.title === 'Chat'){
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a href={`javascript:void(Tawk_API.toggle())`}
                           key={index}
                           className={`${activeRoute === tab.path ? 'active tab': 'tab'}`}>
                            {tab.icon()}
                            <span>{tab.title}</span>
                        </a>
                    )

                } else {
                    return (
                        <Link key={index} to={tab.path} className={`${activeRoute === tab.path ? 'active tab': 'tab'}`}>
                            {tab.icon()}
                            <span>{tab.title}</span>
                        </Link>
                    )
                }
            })}
        </div>
    )
};
