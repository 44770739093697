import React from 'react';
import {Image} from "react-bootstrap";

const Banner = ({image, style, title, content, servingsize}) => {
        return (
            <div className={`normal-banner ${style}`}>
                <Image src={image ? image : '/images/placeholder.png'}/>
                {title || content ? <div className="banner-content">
                    {title ? <h4>{title}</h4> : null}
                    {servingsize ? <p>{servingsize}</p> : null}
                    {content ? <p>{content}</p> : null}
                </div> : null}
            </div>
        );
};

export default Banner;
