import {
    GET_CUSTOMER_AND_OTHER_INFORMATION,
    CALCULATE_DISCOUNT_AND_TAX,
    UPDATE_APP_VIEW,
    GET_CUSTOMER_DETAIL_WITH_ID,
    GET_PREVIOUS_ADDRESSES_WITH_ID, UPDATE_PLATFORM, APPLY_PROMO_CODE
} from '../constants/Customer';

const initialState = {
    ordertypes: [],
    paymentmethods: [],
    discountpercentage: [],
    cities: [],
    customerdetails: [],
    addresses: [],
    loading: false,
    calculations: {},
    calculationLoading: false,
    appView: true,
    addressesLoading: false,
    customerDetailLoading: false,
    promoCodeLoading: false,
    user: {},
    platform: ''
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_CUSTOMER_AND_OTHER_INFORMATION:
            return { ...state, ...action.payload };
        case UPDATE_APP_VIEW:
            return { ...state, appView: action.appView };
        case CALCULATE_DISCOUNT_AND_TAX:
            let calculations = action.payload.calculations ? action.payload.calculations : {};
            return { ...state, calculations, calculationLoading: action.payload.loading };
        case GET_CUSTOMER_DETAIL_WITH_ID:
            const {user} = action.payload;
            return { ...state, user:user, customerDetailLoading: action.payload.loading };
        case GET_PREVIOUS_ADDRESSES_WITH_ID:
            const {addresses} = action.payload;
            return { ...state,
                addresses: addresses,
                addressesLoading: action.payload.loading };
         case UPDATE_PLATFORM:
            return { ...state, platform: action.platform};
        case APPLY_PROMO_CODE:
            return { ...state, promoCodeLoading: action.loading};
        default:
            return state;
    }
};
