import React from 'react';
import RNSlider from "react-slick";
import {Image} from "react-bootstrap";
const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};
function Slider({slides, className, config = settings}) {
    return (
        <div className={`main-slider ${className}`}>
            <RNSlider {...config}>
                {slides ? slides.map((slide, index) => {
                   return(
                       <div className="slider-slide" key={index}>
                           <Image draggable={false} src={slide.imageurl}/>
                       </div>
                   )
                }):null}
            </RNSlider>
        </div>
    );
}

export default Slider;
