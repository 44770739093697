import {
    ADD_ITEM_TO_CART,
    REMOVE_ITEM_TO_CART,
    PROCEED_TO_CHECK_OUT,
    PROCEED_TO_CHECK_OUT_SUCCESS
} from '../constants/Cart';
import {uuid} from "../helper";
const initialState = {
    ordereditems: [],
    loading: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ADD_ITEM_TO_CART:
            return { ...state, ordereditems: [...state.ordereditems, {...action.item, id: uuid()}] };
        case REMOVE_ITEM_TO_CART:
            return { ...state, ordereditems: action.orders };
        case PROCEED_TO_CHECK_OUT:
            return { ...state, loading: action.loading };
        case PROCEED_TO_CHECK_OUT_SUCCESS:
            return { ...state, ordereditems: [], loading: action.loading };
        default:
            return state;
    }
};
