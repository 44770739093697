import React from 'react';
import {Image} from "react-bootstrap";
import Locations from '../../data/locations';


function OurLocation() {

    function renderAddresses(addresses) {
        if(addresses){
            return addresses.map((address, index) => {
                return (
                    <li key={index}>
                        <div className="address-header">
                          <div className="header-inner-container">
                              <h1>{address.place}</h1>
                              <span>({address.orderType})</span>
                          </div>
                        </div>
                        <div className="address-timing">
                            <Image src={require('../../assets/images/clock-icon.png')}/>
                            <p>
                                <b>Timings - </b> {address.timings}
                            </p>
                        </div>
                        <div className="address-address">
                            <Image src={require('../../assets/images/marker.png')}/>
                            <p>
                                <b>Address - </b> {address.address}
                            </p>
                        </div>
                    </li>
                )
            })
        }
        return null
    }

    function renderLocation(locations) {
        if(locations){
            return locations.map((location, index) => {
                return(
                    <li className="location" key={index}>
                        <div className="location-header">
                            <Image src={require('../../assets/images/location-marker.png')}/>
                            <h1>{location.title}</h1>
                        </div>
                        <div className="location-container">
                            {location.addresses ? <ul>
                                {renderAddresses(location.addresses)}
                            </ul> : null}
                        </div>
                    </li>
                )
            })
        }
        return null;

    }

    return (
        <div className="page">
            <div className="page-heading">
                <Image draggable={false} src={require('../../assets/images/headings/our-locations.jpg')}/>
            </div>
            <div className="page-content">
                <ul className="locations">
                    {renderLocation(Locations)}
                </ul>
            </div>
        </div>
    );
}

export default OurLocation;
