import {get} from "../helper";
import {GET_HOME_DATA_API} from "../constants/Home";
const token = '64ad36dd7e876ea29fa5e221d724fe5df8948216';

export const getHomeData = () => (dispatch) => {
    dispatch({type: GET_HOME_DATA_API, loading: true, payload: {}});
    get('mobilehome',{ 
    //     headers: {
    //     "Authorization" : `Bearer ${token}`,
    //     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS,HEAD',
    //     "Access-Control-Allow-Credentials": "true" ,
    //     'Access-Control-Max-Age': '60',
    //     'Access-Control-Allow-Origin': '*'
    // } 
})
        .then(({data}) => {

            let payload = {
                homeBanners: (data && data.blockone) ? data.blockone: [],
                ordering: (data && data.blocktwo) ? data.blocktwo: [],
                deals: (data && data.blockthree) ? data.blockthree: [],
                areas: (data && data.areas) ? data.areas: [],
                subareas: (data && data.subareas) ? data.subareas: [],
                
            };
            dispatch({type: GET_HOME_DATA_API, loading: false, payload });
        })
        .catch((error) => {
            dispatch({type: GET_HOME_DATA_API, loading: false, payload: {}});
            console.log('error', error)
        });
};
