import React, {Component} from "react";
import {Table} from "react-bootstrap";
import {checkout, removeToCart} from "../../store/actions/Cart";
import {connect} from "react-redux";
import {IoIosClose, IoIosCheckmarkCircleOutline} from 'react-icons/io';
import {applyPromoCode, calculateDiscountAndTax} from "../../store/actions/Customer";
import {CustomModal, Loading} from "../../uiComponents";
import decode from 'decode-html';
import {
    deliveryAddressRule,
    emailRule,
    nameRule,
    numberRule, promoCodeRule,
    selectCityRule,
    selectPaymentMethodRule,
    selectedAreaRule,
    selectedSubAreaRule
} from "../../rules";
import {AutoComplete, Form, Input, Select} from "antd";
import {alert} from "../../store/helper";

let form = React.createRef();

class YourOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            payload: {
                taxrate: '',
                timeslotvalue: '',
                orderdeliverydate: '',
                sameday: '',
            },
            paymentMethod: {},
            city: {},
            selectedArea: [],
            selectedSubArea:[],
            activePromoCode: '',
            activeDiscountCampaign: 'websitediscount'
        }
    }

    fixAutocomplete = () => {
        document.querySelectorAll(".ant-select-selector input").forEach((e) => {
            e.setAttribute("autocomplete", "stopDamnAutocomplete");
            //you can put any value but NOT "off" or "false" because they DO NOT works
        })
    }

    componentDidMount() {
        this.fixAutocomplete();
        this.calculateTaxAndDiscountFunction()
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        let {user} = nextProps;
        let payload = {};
        if(user && user.fullname) payload.customername =  user.fullname;
        if(user && user.contatnumber) payload.customercontactnumber =  user.contatnumber;
        if(user && user.email) payload.customeremail =  user.email;
        if(form && form.current){
            form.current.setFieldsValue({ ...payload })
        }
    }

    //calculate sub total, discount and tax.
    calculateTaxAndDiscountFunction = (cityId = "0", taxrate = "0", discountcampaign = "", promocode = '', ordersIsParam) => {
        const {orders, calculateDiscountAndTax, platform} = this.props;
        calculateDiscountAndTax([
            ordersIsParam ? ordersIsParam : orders,
            [{cityId, taxrate, discountcampaign, promocode, platform}]]);
    };

    //remove order in redux.
    callback = () => {};
    removeOrder = (order, orders) => {
        let ordereditems = [...orders];
        const {city, activePromoCode} = this.state;
        const {cityId = '0', taxrate = '0', discountcampaign = ''} = city;
        if(ordereditems.length && ordereditems.length > 0){
            ordereditems = ordereditems.filter(({id}) => id !== order.id);
        }
        this.props.removeToCart(order, ordereditems, this.callback);
        this.calculateTaxAndDiscountFunction(cityId, taxrate, discountcampaign, activePromoCode, ordereditems);
    };

    renderName = (order) => {
        if (order && order.name && order.name.pizzatype) {
            return `${order.name.pizzacrust} ${order.name.pizzasize} ${order.name.meattopping1}`
        } if (order && order.pizzatype) {
            return `${order.pizzacrust} ${order.pizzasize} ${order.meattopping1}`
        } else {
            return order.name;
        }
    };

    //render order in table.
    renderOrders(orders) {
        if (orders) {
            return orders.map((order, index) => {
                return (
                    <tr className='item' key={index}>
                        <td> {index + 1} </td>
                        <td colSpan={2}> {this.renderName(order)} </td>
                        <td> {order.quantity} </td>
                        <td> {order.total} </td>
                        <td className="text-right">
                            <button className='close-button' onClick={() => this.removeOrder(order, orders)}>
                                <IoIosClose/>
                            </button>
                        </td>
                    </tr>
                )
            })
        } else {
            return null;
        }
    }

    //render cities in form.
    renderCities = (cities) => {
        
        if (cities) {
            return cities.map((city, index) => <Select.Option key={index} value={city.cityId}> {city.city} </Select.Option>)
        }
        return null
    };
    renderAreas = (areas) => {

        if (areas) {
            return areas.map((area, index) => <Select.Option key={index} value={area.areaId}> {area.area} </Select.Option>)
        }
        return null
    };
    renderSubAreas = (subAreas) => {

        if (subAreas) {
            return subAreas.map((subarea, index) => <Select.Option key={index} value={subarea.areaId}> {subarea.subarea} </Select.Option>)
        }
        return null
    };
    //onChange city this function will be call.
    cityHandler = (value) => {
        const {cities, areas} = this.props;
        const {activePromoCode} = this.state;
        let selectedCity = cities ? cities.find(city => city.cityId === value) : null;
        if(selectedCity){
            
            let selectedArea = areas ? areas.filter(area => area.cityId === value) : null;
            
            this.setState({city: selectedCity, selectedArea:selectedArea, activeDiscountCampaign: selectedCity.discountcampaign}, () => {
                if(selectedCity){
                    this.calculateTaxAndDiscountFunction(selectedCity.cityId, selectedCity.taxrate, selectedCity.discountcampaign, activePromoCode)
                }
            });
        }
    };

    areaHandler = (value) => {
        const {subAreas} = this.props;
                
        let selectedSubArea = subAreas ? subAreas.filter(subArea => subArea.areaId === value) : null;
        
        if(selectedSubArea){
            this.setState({selectedSubArea:selectedSubArea});
        }
    };

    //render payment method in form.
    renderPaymentMethod = (paymentMethods) => {
        if (paymentMethods) {
            return paymentMethods.map((paymentMethod, index) => <Select.Option key={index} value={paymentMethod.hiddename}>
                {paymentMethod.displayname}
            </Select.Option>)
        }
        return null
    };
    //onChange  payment method function will be call.
    paymentMethodHandler = (value) => {
        const {paymentmethods} = this.props;
        const {city, activePromoCode} = this.state;
        const {cityId = '0', taxrate = '0', discountcampaign = ''} = city;
        let selectedPaymentMethod = paymentmethods ? paymentmethods.find(method => method.hiddename === value) : null;
        this.setState({paymentMethod: selectedPaymentMethod, activeDiscountCampaign: selectedPaymentMethod.discountcampaign}, () => {
            if(selectedPaymentMethod){
                this.calculateTaxAndDiscountFunction(cityId, taxrate, selectedPaymentMethod.discountcampaign, activePromoCode)
            }
        })

    };
    //thank you modal handle
    handleClose = () => this.setState({isVisible: !this.state.isVisible});

    placeOrder = values => {
        const {payload, activePromoCode, activeDiscountCampaign} = this.state;
        const {orders, checkout, calculations, user, platform} = this.props;
        if(!(orders && orders.length)){
            alert('Please add product into cart first.', 'error');
        } else {
            payload.operatorId = user.customerId ? user.customerId : '';
            payload.platform = platform;
            payload.promocode = activePromoCode;
            let customerDetail = {...values, ...payload, ...calculations};
            customerDetail.discountcampaign = activeDiscountCampaign;
            let data = [orders, customerDetail];

            checkout(data);
        }
    };

    renderOptions = (options) => {
        if (options) {
            return options.map((option, index) => (
                <Select.Option key={index} value={option.address}>{option.address}</Select.Option>
            ))
        }
        return null;
    };

    promoCodeCallBack = (response, promocode) => {
        if(response && response.response && response.response === 'success'){
            alert(response.message);
            this.setState({activePromoCode: promocode, activeDiscountCampaign: 'promocodecampaign'}, () => {
                const {city, activePromoCode} = this.state;
                const {cityId = '0', taxrate = '0'} = city;
                this.calculateTaxAndDiscountFunction(cityId, taxrate, 'promocodecampaign', activePromoCode)
            })
        } else {
            alert(response.message, 'error')
        }

    };

    apply = values => this.props.applyPromoCode(values, this.promoCodeCallBack);
    render() {
        const {isVisible, selectedArea, selectedSubArea} = this.state;
        const {orders, paymentmethods, cities, calculations, textinformation,
            loading, calculationLoading, addresses, promoCodeLoading} = this.props;
        return (
            <div className="your-order">
                {loading ? <Loading/> : null}
                <div className="your-order-inner-container">

                    <h1 className="page-title">Your Order</h1>

                    <div className="table-container">
                        {calculationLoading ? <Loading className='transparent-loading' /> : null}
                        <Table bordered>
                            <thead>
                            <tr>
                                <th>Item</th>
                                <th colSpan={2}>Description</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderOrders(orders)}
                            <tr className="footer-item">
                                <td className="border-bottom-white" colSpan="3"/>
                                <td>Sub Total</td>
                                <td colSpan="2" className="text-right">
                                    Rs {calculations.beforetaxamount ? calculations.beforetaxamount : '0'}
                                </td>
                            </tr>
                            <tr className="footer-item">
                                <td className="border-bottom-white" colSpan="3"/>
                                <td>Discount</td>
                                <td colSpan="2" className="text-right">
                                    Rs {calculations.discountamount ? calculations.discountamount : '0'}
                                </td>
                            </tr>
                            <tr className="footer-item">
                                <td className="border-bottom-white" colSpan="3"/>
                                <td>Sales tax (<span>{calculations.taxrate ? calculations.taxrate : '0'}</span>%)</td>
                                <td colSpan="2" className="text-right">
                                    Rs {calculations.taxamount ? calculations.taxamount : '0'}
                                </td>
                            </tr>
                            <tr className="footer-item">
                                <td colSpan="3"/>
                                <td>Delivery Charges</td>
                                <td colSpan="2" className="text-right">
                                    Rs {calculations.deliverycharges ? calculations.deliverycharges : '0'}
                                </td>
                            </tr>
                            <tr className="footer-item">
                                <td colSpan="3"/>
                                <td>Total</td>
                                <td colSpan="2" className="text-right">
                                    Rs {calculations.finalamount ? calculations.finalamount : '0'}
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>

                    {textinformation && textinformation.textblockone ?
                        <div className="info" dangerouslySetInnerHTML={{__html: decode(textinformation.textblockone)}}/>
                        : null}

                    <Form name="f-form" className="form" ref={form}
                          initialValues={{promocode: ''}} onFinish={this.apply}>
                        <div className="promo-section">
                            <span className="promo-section-title">Enter Promo Code:</span>
                            <div className="promo-section-form">
                                <Form.Item name="promocode" rules={promoCodeRule}>
                                    <Input placeholder="Enter promo code here"/>
                                </Form.Item>
                                <Form.Item>
                                    <button disabled={promoCodeLoading} className="c-btn" type="primary" htmltype="submit">
                                        Apply
                                    </button>
                                </Form.Item>
                            </div>
                        </div>

                    </Form>

                    <h1 className="page-title">COMPLETE YOUR ORDER</h1>

                    <div className="form-section">
                        <div className="form">
                            <Form name="f-form"
                                  ref={form}
                                  initialValues={{
                                customercontactnumber: '',
                                paymentmethod: '',
                                customername: '',
                                customeremail: '',
                                ordercityId: '',
                                orderareaId: '',
                                ordersubareaId: '',
                                customeraddress: '',
                                customerlandmark: '',
                                customerextrarequirements: '',
                            }} onFinish={this.placeOrder}>
                                <Form.Item
                                    label="Contact Number"
                                    name="customercontactnumber"
                                    placeholder="ex: 03000000000"
                                    rules={numberRule}>
                                    <Input type="number" />
                                </Form.Item>
                                <Form.Item label="Payment Method" name="paymentmethod" rules={selectPaymentMethodRule}>
                                    <Select style={{ width: '100%' }}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            onChange={this.paymentMethodHandler}>
                                        <Select.Option value="">Select payment method</Select.Option>
                                        {this.renderPaymentMethod(paymentmethods)}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Your Name" name="customername" rules={nameRule}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Your Email" name="customeremail" rules={emailRule}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Your City" name="ordercityId" rules={selectCityRule}>
                                    <Select  style={{ width: '100%' }}
                                             getPopupContainer={trigger => trigger.parentNode}
                                             onChange={this.cityHandler}>
                                        <Select.Option value="">Select your city</Select.Option>
                                        {this.renderCities(cities)}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Area" name="orderareaId" rules={selectedAreaRule}>
                                    <Select style={{ width: '100%' }}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            onChange={this.areaHandler}
                                            >
                                        <Select.Option value="">Select Area</Select.Option>
                                        {this.renderAreas(selectedArea)}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="SubArea" name="ordersubareaId" rules={selectedSubAreaRule}>
                                    <Select style={{ width: '100%' }}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            >
                                        <Select.Option value="">Select Sub Area</Select.Option>
                                        {this.renderSubAreas(selectedSubArea)}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Delivery Address" name="customeraddress" rules={deliveryAddressRule}>
                                    <AutoComplete
                                        getPopupContainer={trigger => trigger.parentNode}
                                        placeholder=""
                                        filterOption={(inputValue, option) =>
                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                    >
                                        {this.renderOptions(addresses)}
                                    </AutoComplete>
                                </Form.Item>
                                <Form.Item label="Nearest Landmark" name="customerlandmark">
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item label="Extra Requirements" name="customerextrarequirements">
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item>
                                    <div className="button-container">
                                        <button disabled={calculationLoading || promoCodeLoading}
                                                className="c-btn c-btn-big"
                                                type="primary" htmltype="submit">
                                            Submit
                                        </button>
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>

                    {textinformation && textinformation.textblocktwo ?
                        <div className="info" dangerouslySetInnerHTML={{__html: decode(textinformation.textblocktwo)}}/>
                        : null}
                </div>
                <CustomModal
                    centered={true}
                    className="thank-you-modal"
                    show={isVisible} handleClose={() => this.handleClose()}>
                    <div className="thank-you-content">
                        <IoIosCheckmarkCircleOutline/>
                        <h1>Thank You For Ordering.</h1>
                    </div>
                </CustomModal>
            </div>
        );
    }

}

const mapStateToProps = ({cart, customer, home}) => {

    return {
        orders: cart.ordereditems,
        loading: cart.loading,
        user: customer.user,
        platform : customer.platform ,
        addresses: customer.addresses,
        addressesLoading: customer.addressesLoading,
        promoCodeLoading: customer.promoCodeLoading,
        paymentmethods: (customer.paymentmethods.length) ? customer.paymentmethods : null,
        cities: (customer.cities.length) ? customer.cities : null,
        calculations: customer.calculations,
        calculationLoading: customer.calculationLoading,
        textinformation: (customer.textinformation) ? customer.textinformation : null,
        areas: (home.areas) ? home.areas : null,
        subAreas: (home.subAreas) ? home.subAreas : null,
    }
};
const mapDispatchToProps = {
    removeToCart,
    calculateDiscountAndTax,
    checkout,
    applyPromoCode
};
export default connect(mapStateToProps, mapDispatchToProps)(YourOrder);
