import {
    GET_SIDELINES_API,
    GET_DIPS_API,
    GET_DRINKS_API,
    GET_SWEET_SOME_THINGS_API
} from '../constants/Menu';

const initialState = {
    loading: false,
    slideLines: [],
    getSlideLinesLoading: false,
    drinks: [],
    getDrinksLoading: false,
    dips: [],
    getDipsLoading: false,
    sweetSomeThings: [],
    getSweetSomeThingsLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_SIDELINES_API:
            return { ...state, slideLines: action.data || [], getSlideLinesLoading: action.loading };
        case GET_DIPS_API:
            return { ...state, dips: action.data || [], getDipsLoading: action.loading };
        case GET_DRINKS_API:
            return { ...state, drinks: action.data || [], getDrinksLoading: action.loading };
        case GET_SWEET_SOME_THINGS_API:
            return { ...state, sweetSomeThings: action.data || [], getSweetSomeThingsLoading: action.loading };
        default:
            return state;
    }
};