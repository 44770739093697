import React from "react";
import {ListItem} from "../index";

const Section = ({
                     title,
                     data,
                     onSelect,
                     selected,
                     type = '',
                     listStyle,
                     titleKey,
                     priceKey = '',
                     servingsizeKey = '',
                     imageKey,
                     idKey,
                     selectedIdKey,
                     flavour
                 }) => {
    return (
        <section className="section-slider">
            <h1>{title}</h1>
            <ListItem
                className={listStyle}
                data={data}
                titleKey={titleKey}
                idKey={idKey}
                selectedIdKey={selectedIdKey}
                imageKey={imageKey}
                priceKey={priceKey}
                servingsizeKey={servingsizeKey}
                type={type}
                flavour={flavour}
                selected={selected}
                onClick={onSelect}
            />
        </section>
    )
};
export default Section;
