import React from 'react';
import {Link} from "react-router-dom";

function More() {
    return (
        <div className="more">
            <ul>
                <li> <Link to="/more/our-location"> Our locations </Link> </li>
                <li> <Link to="/more/feedback"> Feedback </Link> </li>
                <li> <Link to="/more/contact-us"> Contact Us </Link> </li>
                <li> <Link to="/more/franchise-with-us"> Franchise with us </Link> </li>
                <li> <Link to="/more/about-us"> About Us </Link> </li>
                <li> <Link to="more/privacy-policy"> Privacy policy </Link> </li>
            </ul>
        </div>
    );
}

export default More;
