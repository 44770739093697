import {
    GET_DEALS_API,
    GET_DEALS_API_ERROR,
    GET_DEALS_API_SUCCESS,
    GET_DEAL_API
} from "../constants/Deals";
import {get} from "../helper";

export const getDeals = () => (dispatch) => {
    dispatch({type: GET_DEALS_API});
    get('deals')
        .then(({data}) => {
            dispatch({type: GET_DEALS_API_SUCCESS, payload: data});
        })
        .catch((error) => {
            dispatch({type: GET_DEALS_API_ERROR});
            console.log('error', error)
        });
};

export const getDeal = (slug) => (dispatch) => {
    dispatch({type: GET_DEAL_API, loading: true, payload: {}});
    console.log("slug",slug)
    get(`deal/${slug}`)
        .then(({data}) => {
            console.log(data)
            dispatch({type: GET_DEAL_API, loading: false, payload: data});
        })
        .catch((error) => {
            dispatch({type: GET_DEAL_API, loading: false, payload: {}});
            console.log('error', error)
        });
};
