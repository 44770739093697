import {createStore, applyMiddleware} from 'redux';
import reducers from './reducers/index';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
/**
 *
 * Redux Persist Configuration
 */
const persistConfig = {
    key: 'root',
    storage,
    blacklist:['menu', 'more']
};
/**
 *
 * Making Persist Reducers
 */
const persistedReducer = persistCombineReducers(persistConfig, reducers);

// export const store = createStore(persistedReducer, {}, applyMiddleware(thunk, logger));
export const store = createStore(persistedReducer, {}, applyMiddleware(thunk));
export const persistor = persistStore(store);
