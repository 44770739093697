import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom';
import {
    Auth,
    Login,
    Chat,
    Home,
    QuickOrdering,
    Deal,
    Deals,
    Menu,
    More,
    Pizza,
    YourOrder,
    Variations,
    AboutUs,
    OurLocation,
    FranchiseWithUs,
    Feedback,
    ContactUs,
    PrivacyPolicy
} from '../pages';
import {TabWithContainer, HeaderWithBody, NotFound} from "../uiComponents";


function Routing() {
    return (
        <Fragment>
            <Switch>
                {/*<Route exact path="/" component={Auth}/>*/}
                {/*<Route path="/login" component={Login}/>*/}
                <Route exact path="/" component={TabWithContainer(Home)}/>
                <Route path="/quickordering" component={HeaderWithBody(QuickOrdering, true,true, false)}/>
                <Route path="/yourorder" component={HeaderWithBody(YourOrder, true,false, false)}/>
                <Route path="/pizza/:id" component={HeaderWithBody(Pizza, true,true, false)}/>
                <Route path="/deal/:slug" component={HeaderWithBody(Deal, true,true, false)}/>
                <Route path="/deals" component={TabWithContainer(Deals)}/>
                <Route path="/variations" component={HeaderWithBody(Variations, true,true, false)}/>
                <Route path="/chat" component={TabWithContainer(Chat)}/>
                <Route path="/more/about-us" component={HeaderWithBody(AboutUs, true,true, false)}/>
                <Route path="/more/privacy-policy" component={HeaderWithBody(PrivacyPolicy, true,true, false)}/>
                <Route path="/more/feedback" component={HeaderWithBody(Feedback, true,true, false)}/>
                <Route path="/more/franchise-with-us" component={HeaderWithBody(FranchiseWithUs, true,true, false)}/>
                <Route path="/more/our-location" component={HeaderWithBody(OurLocation, true,true, false)}/>
                <Route path="/more/contact-us" component={HeaderWithBody(ContactUs, true,true, false)}/>
                <Route path="/more" component={TabWithContainer(More)}/>
                <Route path="/" component={TabWithContainer(Menu)}/>
                <Route path="*" component={HeaderWithBody(NotFound)}/>
            </Switch>
        </Fragment>
    )
}

export default Routing;
