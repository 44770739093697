import React from 'react';
import {Image} from "react-bootstrap";
import {Form, Input, Select} from 'antd';
import {
    emailRule,
    numberRule,
    nameRule,
    cityRule,
    countryRule,
    hearAboutRule,
    postalAddressRule
} from '../../rules';
import {connect} from "react-redux";
import {submitFranchiseQuery} from "../../store/actions/More";
import {Loading} from "../../uiComponents";

const CForm = ({loading, submit}) => {
    const [form] = Form.useForm();

    const callback = () => {
        form.resetFields();
    }

    const onFinish = values => {
        let formdata = new FormData();
        formdata.append("name", values.name);
        formdata.append("email", values.email);
        formdata.append('phone', values.phone);
        formdata.append('postal_address', values.postal_address);
        formdata.append('city', values.city);
        formdata.append('country', values.country);
        formdata.append('about', values.about);
        submit(formdata, callback);
    };

    return (
        <Form name="f-form" form={form} initialValues={{}} onFinish={onFinish}>
            <Form.Item label="Name" name="name" rules={nameRule}>
                <Input/>
            </Form.Item>
            <Form.Item label="Email" name="email" rules={emailRule}>
                <Input/>
            </Form.Item>
            <Form.Item label="Phone" name="phone" rules={numberRule}>
                <Input type="number" />
            </Form.Item>
            <Form.Item label="Postal Address" name="postal_address" rules={postalAddressRule}>
                <Input/>
            </Form.Item>
            <Form.Item label="City" name="city" rules={cityRule}>
                <Input/>
            </Form.Item>
            <Form.Item label="Country" name="country" rules={countryRule}>
                <Input/>
            </Form.Item>
            <Form.Item label="How did you hear about us ?" name="about" rules={hearAboutRule}>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Please select"
                >
                    <Select.Option key='Radio'>Radio</Select.Option>
                    <Select.Option key='Facebook'>Facebook</Select.Option>
                    <Select.Option key='Print Ad'>Print Ad</Select.Option>
                    <Select.Option key='Through friends'>Through friends</Select.Option>
                    <Select.Option key='Billboard'>Billboard</Select.Option>

                </Select>
            </Form.Item>

            <Form.Item>
              <div className="button-container">
                  <button disabled={loading}
                          className="c-btn"
                          type="primary" htmlType="submit">
                      Submit
                  </button>
              </div>
            </Form.Item>
        </Form>
    );
};

function FranchiseWithUs({loading, submitFranchiseQuery}) {
    return (
        <div className="page">
            {loading ? <Loading /> : null}
            <div className="page-heading">
                <Image draggable={false} src={require('../../assets/images/headings/franchise-with-us.jpg')}/>
            </div>
            <div className="page-content">
                <div className="form">
                    <CForm
                        loading={loading}
                        submit={submitFranchiseQuery}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({more}) => {
    return {
        loading: more.loading
    }
};
const mapDispatchToProps = {
    submitFranchiseQuery
};
export default connect(mapStateToProps, mapDispatchToProps)(FranchiseWithUs);
