import {
    GET_SIDELINES_API,
    GET_DIPS_API,
    GET_DRINKS_API,
    GET_SWEET_SOME_THINGS_API
} from '../constants/Menu';
import { get } from "../helper";

export const getSidelines = () => (dispatch) => {
    dispatch({type: GET_SIDELINES_API, loading: true});
    get('sidelines')
        .then((response) => {
            let data = response && response.data && response.data.sidelines ? response.data.sidelines : [];
            dispatch({type: GET_SIDELINES_API, data, loading: false});
            // console.log('response', response)
        })
        .catch((error) => {
            dispatch({type: GET_SIDELINES_API, loading: false});
            console.log('error', error)
        });
};

export const getDrinks = () => (dispatch) => {
    dispatch({type: GET_DRINKS_API, loading: true});
    get('drinks')
        .then((response) => {
            let data = response && response.data && response.data.drinks ? response.data.drinks : [];
            dispatch({type: GET_DRINKS_API, data, loading: false});
            // console.log('response', response)
        })
        .catch((error) => {
            dispatch({type: GET_DRINKS_API, loading: false});
            console.log('error', error)
        });
};
export const getDips = () => (dispatch) => {
    dispatch({type: GET_DIPS_API, loading: true});
    get('dips')
        .then((response) => {
            let data = response && response.data && response.data.dips ? response.data.dips : [];
            dispatch({type: GET_DIPS_API, data, loading: false});
            // console.log('response', response)
        })
        .catch((error) => {
            dispatch({type: GET_DIPS_API, loading: false});
            console.log('error', error)
        });
};
export const getSweetSomeThings = () => (dispatch) => {
    dispatch({type: GET_SWEET_SOME_THINGS_API, loading: true});
    get('sweetsomethings')
        .then((response) => {
            let data = response && response.data && response.data.sweetsomethings ? response.data.sweetsomethings : [];
            dispatch({type: GET_SWEET_SOME_THINGS_API, data, loading: false});
            // console.log('response', response)
        })
        .catch((error) => {
            dispatch({type: GET_SWEET_SOME_THINGS_API, loading: false});
            console.log('error', error)
        });
};