import React, { Component } from 'react';
import { connect } from "react-redux";
import { getDeal } from "../../store/actions/Deals";
import { Banner, Section, Loading } from "../../uiComponents";
import { addToCart } from "../../store/actions/Cart";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Image } from "react-bootstrap";

const VariationCard = ({ children, title, type }) => {
    return (
        <div className="variations-container padding-l-r-zero">
            <div className="variations-card">
                <div className="list">
                    <div className="list-header">
                        <div className="list-header-left">
                            <span className="bold"> {title} </span>
                            <span> Select </span>
                        </div>
                    </div>
                    <div className="section-slider m-0">
                        <ul className={`c-list ${type === 'addons' ? 'flex-direction-column' : ''}`}>
                            {children}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

class Deal extends Component {

    state = {
        pizzas: [],
        drinks: [],
        sweetSomething: [],
        sideliners: [],
        addOns: {},
        quantity: 1
    };

    clearState = () => {
        this.setState({
            pizzas: [],
            drinks: [],
            sweetSomething: [],
            sideliners: [],
            addOns: {},
            quantity: 1
        })
    }

    componentDidMount(): void {
        const { getDeal, history } = this.props;
        // const {state} = location;
        let pathname = window.location ? window.location.pathname : '';
        if (pathname && pathname.search(/deal/)) {
            let url = pathname.replace('/deal/', '');
            getDeal(url)
        } else {
            history.goBack()
        }
    }

    setVariation = (variationIndex, stateKey, selectedValue) => {
        let stateValue = this.state[stateKey];
        stateValue[variationIndex] = {
            producttype: stateKey,
            name: selectedValue.name,
            price: 0,
            quantity: 1,
            total: 0
        };
        this.setState({ [stateKey]: stateValue });
    };
    renderVariations = (variationIndex, variations, selectedVariation, stateKey, imageKey) => {
        if (variations) {
            return variations.map((variation, index) => {
                let activeClass = (selectedVariation && selectedVariation.name === variation.name ? 'active' : '');
                return (
                    <li className={activeClass}
                        key={index}
                        onClick={() => this.setVariation(variationIndex, stateKey, variation)}>
                        <Image src={variation.image ? variation.image : '/images/placeholder.png'} />
                        <span>{variation.name}</span>
                    </li>
                )
            })
        }
        return null
    };

    setChoice = (key, value) => {
        let { addOns } = this.state;
        addOns[key] = value;
        this.setState({ addOns });
    };
    renderChoices = (choices, price, key) => {
        if (choices) {
            return choices.map((choice, index) => {
                let addOns = this.state.addOns;
                let selected = addOns[key] ? addOns[key] : {};
                let activeClass = (selected && selected.name === choice) ? 'active' : '';
                return (
                    <div className="list-item" key={index} onClick={() => this.setChoice(key, {
                        producttype: key,
                        name: choice,
                        price: Number(price),
                        total: Number(price),
                        quantity: 1,
                        addon: 1
                    })}>
                        <span className={`icon ${activeClass}`} />
                        <span>{choice}</span>
                    </div>
                )
            })
        }
        return null
    };

    //for signature pizza
    getSignatureConfiguration = (id, signatureConfiguration, key) => {
        if (Object.keys(signatureConfiguration).length && signatureConfiguration[id]) {
            let value = signatureConfiguration[id][key] ? signatureConfiguration[id][key] : ''
            if (value.indexOf(',') > -1) {
                return value.split(',')
            } else {
                return value
            }
        } else {
            return ''
        }
    };

    //extra cheese
    checkExtraCheese = (data) => {
        let extraCheese = '';
        if (data && data.length && data.includes('ExtraCheese')) {
            extraCheese = 'yes';
        }
        return extraCheese;
    };
    getExtraCheese = (data, key, data2) => {
        let price = 0;
        if (data && data[key] && data[key].length) {
            data[key].map(obj => {
                if (data2.includes('ExtraCheese') && obj.type === 'ExtraCheese') {
                    price += obj.price
                }
            })
        }
        return price;
    };

    //for pizza
    objectConvertIntoArray = (data, key, objKeyName) => {
        let updatedValue = [];
        if (data && data[key] && data[key].length) {
            data[key].map(obj => updatedValue.push(obj[objKeyName]))
        }

        return updatedValue;
    };

    selectVeggies = (key, selected, pizzaIndex) => {
        let { pizzas } = this.state;
        let selectedPizza = pizzas[pizzaIndex] ? pizzas[pizzaIndex] : {};
        let selectedData = selectedPizza[key] ? selectedPizza[key] : [];
        if (selectedData.length) {
            let index = selectedData.indexOf(selected.vegetablename);
            if (index !== -1) {
                selectedData.splice(index, 1)
            } else {
                selectedData.push(selected.vegetablename)
            }
        } else {
            selectedData.push(selected.vegetablename)
        }
        selectedPizza[key] = selectedData;
        this.setState({ pizzas })
    };
    selectExtra = (key, selected, pizzaIndex, price) => {
        let { pizzas } = this.state;
        let selectedPizza = pizzas[pizzaIndex] ? pizzas[pizzaIndex] : {};
        let selectedData = selectedPizza[key] ? selectedPizza[key] : [];
        if (selectedData.length) {
            let index = selectedData.indexOf(selected);
            if (index !== -1) {
                selectedData.splice(index, 1)
                if (selectedPizza.price) {
                    selectedPizza.price -= price;
                }
            } else {
                if (selectedPizza.price) {
                    selectedPizza.price += price;
                } else {
                    selectedPizza.price = price;
                }
                selectedData.push(selected)
            }
        } else {
            if (selectedPizza.price) {
                selectedPizza.price += price;
            } else {
                selectedPizza.price = price;
            }
            selectedData.push(selected)
        }
        selectedPizza[key] = selectedData;
        this.setState({ pizzas })
    };

    selectExtraWithLimit = (key, selected, pizzaIndex, limit, price) => {
        let { pizzas } = this.state;
        let selectedPizza = pizzas[pizzaIndex] ? pizzas[pizzaIndex] : {};
        let selectedData = selectedPizza[key] ? selectedPizza[key] : [];
        if (selectedData.length) {
            let index = selectedData.indexOf(selected);
            if (index !== -1) {
                selectedData.splice(index, 1)
                if (selectedPizza.price) {
                    selectedPizza.price -= price;
                }
            } else if (limit && selectedData.length < limit) {
                if (selectedPizza.price) {
                    selectedPizza.price += price;
                } else {
                    selectedPizza.price = price;
                }
                selectedData.push(selected)
            }
        } else {
            if (selectedPizza.price) {
                selectedPizza.price += price;
            } else {
                selectedPizza.price = price;
            }
            selectedData.push(selected)
        }
        selectedPizza[key] = selectedData;

        this.setState({ pizzas })
    };

    selectFlavorsWithType = (pizza, selectedPizza, { flavor }) => {

        let limit = pizza.flavorallowed;
        let selectedP = selectedPizza.meattopping1;
        let data;

        if (Array.isArray(selectedP)) {
            data = [...selectedP];
            if (data.length) {
                let index = data.indexOf(flavor);
                if (index !== -1) {
                    data.splice(index, 1)
                } else if (limit && data.length < limit) {
                    data.push(flavor)
                }
            } else {
                data.push(flavor)
            }
        } else {
            data = [flavor]
        }

        return data;

    };

    onSelect = (selected, pizzaIndex) => {
        const pizza = this.props.pizzas && this.props.pizzas[pizzaIndex] ? this.props.pizzas[pizzaIndex] : {};
        let { pizzas } = this.state;
        let { signatureConfiguration } = this.props;
        let selectedPizza = pizzas[pizzaIndex] ? pizzas[pizzaIndex] : {};
        selectedPizza = {
            pizzatype: 'dealpizza',
            pizzacrust: pizza.crust,
            pizzasize: pizza.size,
            pizzaflavor: selected.type === 'signature' ? this.getSignatureConfiguration(selected.flavorId, signatureConfiguration, 'sauce') : 'Secret',
            meattopping1: selected.type === 'signature' ? selected.flavor : this.selectFlavorsWithType(pizza, selectedPizza, selected),
            meattopping2: '',
            extrameattopping1: '',
            extrameattopping2: '',
            vegetables1: (selected.type === 'signature') ? this.getSignatureConfiguration(selected.flavorId, signatureConfiguration, 'vegetables') : '',
            vegetables2: '',
            sweettoppings: '',
            extracheese: '',
            pizzaquantity: 1,
            type: selected.type
        };
        pizzas[pizzaIndex] = selectedPizza;
        this.setState({ pizzas });
        // console.log(signatureConfiguration)
    };

    selectSauce = (pizzaIndex, selected) => {
        let { pizzas } = this.state;
        let selectedPizza = pizzas[pizzaIndex] ? pizzas[pizzaIndex] : {};
        selectedPizza.pizzaflavor = selected.saucename;
        this.setState({ pizzas });
    };

    //render pizzas
    renderPizza = (pizzas) => {
        if (pizzas && pizzas.length) {
            return pizzas.map((pizza, index) => {
                let title = `Select Pizza ${index + 1} - ${pizza.size}`;
                const { pizzas } = this.state;
                let selectedPizza = pizzas[index] ? pizzas[index] : [];
                let selectedFlavour = selectedPizza ? selectedPizza : {};
                if (pizza.flavors && pizza.flavors.length) {
                    return (
                        <div key={index} className="variations-container padding-l-r-zero">
                            <div className="variations-card">
                                <div className="list-header">
                                    <div className="list-header-left margin-bottom-15">
                                        <span className="bold"> {title} </span>
                                    </div>
                                </div>
                                <div className="variations-body">
                                    <div className="list margin-bottom-15">
                                        <div className="list-header">
                                            <div className="list-header-left">
                                                <span className="bold"> Signature Flavors</span>
                                                <span> Select flavor </span>
                                            </div>
                                        </div>
                                        <div className="section-slider m-0">
                                            <ul className="c-list">
                                                {this.renderFlavors(pizza.flavors, 'signature', index)}
                                            </ul>
                                        </div>

                                    </div>
                                    <span className="or-separator"> OR </span>
                                    <div className="list">
                                        <div className="list-header">
                                            <div className="list-header-left">
                                                <span className="bold"> Customize Your Own Pizza </span>
                                                <span> Select flavor </span>
                                            </div>
                                        </div>
                                        <div className="section-slider m-0">
                                            <ul className="c-list">
                                                {this.renderFlavors(pizza.flavors, 'customize', index)}
                                            </ul>
                                        </div>
                                        {selectedFlavour.type === 'customize' ? this.renderSauces(pizza.sauces, index) : null}

                                        {selectedFlavour.type === 'customize' ? this.renderVegetables(pizza, pizza.vegetables, index) : null}

                                        {selectedFlavour.type === 'customize' ? this.renderExtraFlavor(pizza.ExtraFlavor, index) : null}

                                        {selectedFlavour.type === 'customize' ? this.renderExtraAddons(pizza, pizza.ExtraAddons, index) : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                return null;
            })
        }
        return null
    };
    renderFlavors = (flavors, flavorType, pizzaIndex) => {
        let { pizzas } = this.state;
        if (flavors && flavors.length) {
            let selectedPizza = pizzas[pizzaIndex] ? pizzas[pizzaIndex] : [];
            let selected = selectedPizza ? selectedPizza : {};
            return flavors.map((flavor, index) => {
                let activeClass = '';
                if (flavorType === 'customize') {
                    activeClass = ((selected && selected.meattopping1 && selected.meattopping1.includes(flavor.flavor)) ? 'active' : '');
                } else {
                    activeClass = ((selected && selected.meattopping1) === flavor.flavor ? 'active' : '');
                }

                if (flavor.type === flavorType) {
                    return (
                        <li className={`${activeClass ? 'active' : ''}`}
                            key={index}
                            onClick={() => this.onSelect(flavor, pizzaIndex)}>
                            <Image src={flavor.image ? flavor.image : '/images/placeholder.png'} />
                            <span>{flavor.flavor}</span>
                        </li>
                    )
                }
                return null;
            })
        }
        return null
    };
    renderSauces = (sauces, index) => {
        let { pizzas } = this.state;
        let selectedPizza = pizzas[index] ? pizzas[index] : [];
        let selected = (selectedPizza && selectedPizza.pizzaflavor) ? { saucename: selectedPizza.pizzaflavor } : {};
        if (sauces && sauces.length) {
            return (
                <Section
                    title="Select Sauces"
                    data={sauces}
                    titleKey="saucename"
                    idKey="saucename"
                    selectedIdKey="saucename"
                    selected={selected}
                    onSelect={value => this.selectSauce(index, value)}
                />
            )
        }
        return null
    };
    renderVegetables = (pizza, pVegetables, index) => {
        let { pizzas } = this.state;
        let selectedPizza = pizzas[index] ? pizzas[index] : [];
        let selected = (selectedPizza && selectedPizza.vegetables1) ? selectedPizza.vegetables1 : [];
        if (pVegetables && pVegetables.length) {
            return (
                <Section
                    title="Select veggies"
                    data={pizza.vegetables}
                    type="array-with-choices"
                    titleKey="vegetablename"
                    selected={selected}
                    onSelect={value => this.selectVeggies('vegetables1', value, index)}
                />
            )
        }
        return null
    };
    renderExtraFlavor = (eFlavors, index) => {
        let { pizzas } = this.state;
        let selectedPizza = pizzas[index] ? pizzas[index] : [];
        let selected = (selectedPizza && selectedPizza.extrameattopping1) ? selectedPizza.extrameattopping1 : [];
        if (eFlavors && eFlavors.choices.length) {
            return (
                <Section
                    title="Select extra Flavor"
                    data={eFlavors.choices}
                    type="array-with-choices"
                    titleKey="name"
                    priceKey='price'
                    selected={selected}
                    onSelect={({ name }) => this.selectExtraWithLimit('extrameattopping1', name, index, eFlavors.choicesallowed, eFlavors.price)}
                />
            )
        }
        return null
    };
    renderExtraAddons = (pizza, extraAddons, index) => {
        let { pizzas } = this.state;
        let selectedPizza = pizzas[index] ? pizzas[index] : [];
        let selected = (selectedPizza && selectedPizza.sweettoppings) ? selectedPizza.sweettoppings : [];
        if (extraAddons && extraAddons.length) {
            return (
                <Section
                    title="Select extra toppings"
                    data={extraAddons}
                    type="array-with-choices"
                    titleKey="type"
                    priceKey='price'
                    selected={selected}
                    onSelect={({ type, price }) => this.selectExtra('sweettoppings', type, index, price)}
                />
            )
        }
        return null
    };
    //drinks
    renderDrinks = (drinks) => {
        if (drinks && drinks.length) {
            return drinks.map((data, index) => {
                let { drinks } = this.state;
                let title = `Select Drink ${index === 0 ? '' : index + 1}`;
                let selected = drinks[index] ? drinks[index] : {};
                return (
                    <VariationCard key={index} title={title}>
                        {this.renderVariations(index, data, selected, 'drinks')}
                    </VariationCard>
                )
            });
        }
        return null;
    };
    //side lines
    renderSideLines = (sideLines) => {
        if (sideLines && sideLines.length) {
            return sideLines.map((data, index) => {
                let { sideliners } = this.state;
                let title = `Select Sidelines ${index === 0 ? '' : index + 1}`;
                let selected = sideliners[index] ? sideliners[index] : {};
                return (
                    <VariationCard key={index} title={title}>
                        {this.renderVariations(index, data, selected, 'sideliners')}
                    </VariationCard>
                )
            });
        }
        return null;
    };
    //sweet somethings
    renderSweetSomethings = (sweetSomethings) => {
        if (sweetSomethings && sweetSomethings.length) {
            return sweetSomethings.map((data, index) => {
                let { sweetSomething } = this.state;
                let title = `Select Sweet Somethings ${index === 0 ? '' : index + 1}`;
                let selected = sweetSomething[index] ? sweetSomething[index] : {};
                return (
                    <VariationCard key={index} title={title}>
                        {this.renderVariations(index, data, selected, 'sweetSomething')}
                    </VariationCard>
                )
            });
        }
        return null;
    };
    //add-ons
    renderAddOns = (addOns) => {
        if (addOns && addOns.length) {
            return addOns.map((data, index) => {
                let title = `Select Addon ${index === 0 ? '' : index + 1} ${data.price ? `- Rs ${data.price}` : ''}`;
                return (
                    <VariationCard key={index} title={title} type='addons'>
                        {this.renderChoices(data.choices, data.price, data.type)}
                    </VariationCard>
                )
            });
        }
        return null;
    };

    getTotalPrice = (array, dealPrice, quantity) => {
        let price = Number(dealPrice);
        array.forEach(obj => {
            if (obj.price) price += Number(obj.price)
        });
        price = price * Number(quantity);
        return price
    };

    modifyPizzas = (array) => {
        return array.map((pizza, index) => {
            pizza.extracheese = pizza.sweettoppings && pizza.sweettoppings.length ? this.checkExtraCheese(pizza.sweettoppings) : 'No';
            pizza.sweettoppings = pizza.sweettoppings && pizza.sweettoppings.length ? pizza.sweettoppings.filter(v => v !== 'ExtraCheese') : '';
            delete pizza.type;
            return pizza
        })
    };

    addToCart = () => {

        const { pizzas, drinks, sweetSomething, sideliners, addOns, quantity } = this.state;
        const { dealInfo, addToCart } = this.props;
        let payload = {
            producttype: 'deals',
            name: dealInfo.name,
            nametodisplay: dealInfo.name,
            price: Number(dealInfo.price),
            quantity: quantity,
            pricefortax: dealInfo.pricefortax,
            total: 0,
            addon: 0,
            dealitems: []
        };

        if (pizzas && pizzas.length) {
            payload.dealitems = [...payload.dealitems, ...this.modifyPizzas(pizzas)]
        }
        if (drinks && drinks.length) {
            payload.dealitems = [...payload.dealitems, ...drinks]
        }
        if (sweetSomething && sweetSomething.length) {
            payload.dealitems = [...payload.dealitems, ...sweetSomething]
        }
        if (sideliners && sideliners.length) {
            payload.dealitems = [...payload.dealitems, ...sideliners]
        }
        if (addOns && addOns.drinks) {
            payload.dealitems = [...payload.dealitems, addOns.drinks]
        }
        if (addOns && addOns.sideliners) {
            payload.dealitems = [...payload.dealitems, addOns.sideliners]
        }
        if (addOns && addOns.sweetSomething) {
            payload.dealitems = [...payload.dealitems, addOns.sweetSomething]
        }

        payload.total = this.getTotalPrice(payload.dealitems, dealInfo.price, quantity);

        addToCart(payload);
        this.clearState();
    };

    increment = () => this.setState({ quantity: this.state.quantity + 1 });
    decrement = () => (this.state.quantity >= !0) && this.setState({ quantity: this.state.quantity - 1 });

    render() {
        const { quantity } = this.state;
        const { pizzas, drinks, sweetSomethings, sideLines, addOns, loading, dealInfo } = this.props;
        let disabled = true;
        let check = 0;
        const total = [dealInfo.totalpizzas, dealInfo.totaldrinks, dealInfo.totalsidelines, dealInfo.totalsweetsomethings];
        
        // HERE IS THE CONDITION THAT CHECK ALL DEAL ITEMS ARE SELECTED OR NOT
        for (let x = 0; x < total.length; x++) {
            const element = total[x];
            if (element > 0) {
                if (x == 0 && this.state.pizzas.length == 0) {
                    check++;
                }
                if (x == 0 && this.state.pizzas.length > 0 && this.state.pizzas[0].pizzaflavor == "Secret" && this.state.pizzas[0].pizzacrust !== "Xtreme" ) {
                    check++;
                }
                if (x === 1 && this.state.drinks.length == 0) {
                    check++;
                }
                if (x === 2 && this.state.sideliners.length == 0) {
                    check++;
                }
                if (x === 3 && this.state.sweetSomething.length == 0) {
                    check++;
                }
            }
        }

        if (check == 0) {
            disabled = false;
        }

        return (
            <>
                <div className="inner-body-with-no-scroll deal variations">
                    {loading ? <Loading /> : <>
                        <Banner
                            style='secondary-banner'
                            image={dealInfo && dealInfo.imageurl ? dealInfo.imageurl : ''}
                            title={dealInfo && dealInfo.name ? dealInfo.name : ''}
                            content={dealInfo && dealInfo.description ? dealInfo.description : ''}
                            servingsize={dealInfo && dealInfo.servingsize ? dealInfo.servingsize : ''}
                        />

                        {this.renderPizza(pizzas)}
                        {this.renderSideLines(sideLines)}
                        {this.renderDrinks(drinks)}
                        {this.renderSweetSomethings(sweetSomethings)}
                        {this.renderAddOns(addOns)}
                    </>}
                </div>
                <div className="common-footer">
                    <div className="counter">
                        <button className="minus" onClick={() => this.decrement()}>
                            <MinusOutlined />
                        </button>
                        <span className="counter-value">{quantity}</span>
                        <button className="plus" onClick={() => this.increment()}>
                            <PlusOutlined />
                        </button>
                    </div>
                    <button disabled={disabled}
                        className="c-btn"
                        onClick={() => this.addToCart()}>
                        Add to cart
                    </button>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ deals }) => {
    let { dealitemsselectioninformation = {}, dealbasicinformaion = {} } = deals.deal;
    return {
        loading: deals.dealLoading,
        dealInfo: dealbasicinformaion,
        pizzas: (dealitemsselectioninformation && dealitemsselectioninformation.pizzas) ? dealitemsselectioninformation.pizzas : [],
        signatureConfiguration: (dealitemsselectioninformation && dealitemsselectioninformation.signatureconfiguration) ? dealitemsselectioninformation.signatureconfiguration : {},
        drinks: (dealitemsselectioninformation && dealitemsselectioninformation.drinks) ? dealitemsselectioninformation.drinks : [],
        sweetSomethings: (dealitemsselectioninformation && dealitemsselectioninformation.sweetsomethings) ? dealitemsselectioninformation.sweetsomethings : [],
        sideLines: (dealitemsselectioninformation && dealitemsselectioninformation.sidelines) ? dealitemsselectioninformation.sidelines : [],
        addOns: (dealitemsselectioninformation && dealitemsselectioninformation.addons) ? dealitemsselectioninformation.addons : [],
    }
};
const mapDispatchToProps = {
    getDeal,
    addToCart
};
export default connect(mapStateToProps, mapDispatchToProps)(Deal);
