import React from 'react';
import { Header } from "..";

const HeaderWithBody = (Element, back = false, cart = false, menu = false) => props => {
    return (
        <div className="header-with-body">
            <Header back={back} cart={cart} menu={menu}/>
            <div className="body">
                <Element {...props}/>
            </div>
        </div>
    );
};
export default HeaderWithBody
