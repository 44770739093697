import axios from "axios";
import {message} from "antd";

export const baseUrl = 'https://www.fspcmobile.com/webservices/';

export const interceptor = () => {
    axios.defaults.baseURL = baseUrl;
    axios.interceptors.request.use(
        function (config) {
            //config.headers["Access-Control-Allow-Origin"] = "*";
            //config.headers["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS";
            config.headers.Authorization  = "Bearer cbc4f1627f069eefc309485ecc8af4b9876b4a20";
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            return Promise.reject(error);
        }
    )
};
export const uuid = () => {
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
        // return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        return s4() + s4() + '-' + s4();
}
export const get = (url) => {
    return axios.get(url)
};
export const post = (url, data) => {
    return axios.post(url, data)
};

export const alert = (text = 'This is a success message', type = 'success') => {
    if(type === 'error') {
        message.error(text);
    } else if (type === 'warning'){
        message.warning(text);
    } else if (type === 'info'){
        message.info(text);
    } else {
        message.success(text);
    }
};

export const slugify = (text) => {
    return text
        .toString()                     // Cast to string
        .toLowerCase()                  // Convert the string to lowercase letters
        .normalize('NFD')       // The normalize() method returns the Unicode Normalization Form of a given string.
        .trim()                         // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}
