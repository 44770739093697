import {
    GET_PIZZA_ORDERING_API,
    GET_QUICK_PIZZA_ORDERING_API
} from '../constants/Ordering';

const initialState = {
    loading: false,
    quickPizzaOrdering: {
        pizzacrusts: [],
        pizzasizes: {},
        pizzaflavors: {}
    },
    pizzaOrdering: {
        pizzacrusts: [],
        pizzasizes: {},
        pizzaflavors: {}
    }
};

export default (state = initialState, action = { payload: {}}) => {
    let payload = {
        pizzacrusts: (action.payload && action.payload.pizzacrusts) ? action.payload.pizzacrusts : [],
        pizzasizes: (action.payload && action.payload.pizzasizes) ? action.payload.pizzasizes : {},
        pizzaflavors: (action.payload && action.payload.pizzaflavors) ? action.payload.pizzaflavors : {}
    };
    switch (action.type) {
        case GET_QUICK_PIZZA_ORDERING_API:
            return { ...state, loading: action.loading, quickPizzaOrdering : payload };
        case GET_PIZZA_ORDERING_API:
            return { ...state, loading: action.loading, pizzaOrdering : payload };
        default:
            return state;
    }
};