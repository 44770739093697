import React from 'react';
import { Modal } from "react-bootstrap";

function CustomModal(props) {
    const {show, handleClose, children, heading = '', size = 'lg', className = ''} = props;
    return (
        <Modal {...props} show={show} size={size} onHide={handleClose} className={`custom-modal ${className}`}>
           {heading ? <Modal.Header>
               <Modal.Title>{heading}</Modal.Title>
           </Modal.Header> : null}
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    );
}

export default CustomModal;