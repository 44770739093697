import { GET_HOME_DATA_API } from "../constants/Home";

const initialState = {
  loading: false,
  homeBanners: [],
  deals: [],
  ordering: [],
  areas: [],
  subAreas: [],
};

export default (state = initialState, action = { payload: {} }) => {
  switch (action.type) {
    case GET_HOME_DATA_API:
      let homeBanners =
        action && action.payload ? action.payload.homeBanners : [];
      let deals = action && action.payload ? action.payload.deals : [];
      let ordering = action && action.payload ? action.payload.ordering : [];
      let areas = action && action.payload ? action.payload.areas : [];
      let subAreas = action && action.payload ? action.payload.subareas : [];

      return {
        ...state,
        loading: action.loading,
        homeBanners,
        deals,
        ordering,
        areas,
        subAreas,
      };
    default:
      return state;
  }
};
