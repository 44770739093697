import {
    GET_DEALS_API,
    GET_DEALS_API_ERROR,
    GET_DEALS_API_SUCCESS,
    GET_DEAL_API
} from "../constants/Deals";

const initialState = {
    dealtypes: [],
    dealtypesdetails: {},
    dealbanners: [],
    loading: false,
    dealLoading: false,
    deal: {}
};

export default (state = initialState, action = {payload : {}}) => {
    switch (action.type) {
        case GET_DEALS_API:
            return {...state, loading: true};
        case GET_DEALS_API_SUCCESS:
            let dealtypes = (action.payload && action.payload.dealtypes) ? action.payload.dealtypes : [];
            let dealtypesdetails = (action.payload && action.payload.dealtypesdetails) ? action.payload.dealtypesdetails : {};
            let dealbanners = (action.payload && action.payload.dealbanners) ? action.payload.dealbanners : [];
            return {...state, dealtypes, dealtypesdetails, dealbanners, loading: false};
        case GET_DEALS_API_ERROR:
            return {...state, loading: false};
        case GET_DEAL_API:
            return {...state, dealLoading: action.loading, deal: action.payload};
        default:
            return state;
    }
};
