import React from 'react';
import {Image} from "react-bootstrap";

function PrivacyPolicy() {
    return (
        <div className="page">
            <div className="page-heading">
                <Image draggable={false} src={require('../../assets/images/headings/privacy-policy.jpg')}/>
            </div>
            <div className="page-content">
                <p>By surfing our website and interacting with its contents, you agree to the following.</p>
                <ul>
                    <li>
                        1. Following personal information will be collected.
                        <ul>
                            <li>
                                a. We will collect data about your activities that does not personally or directly
                                identify you when you visit our website.
                            </li>
                            <li>
                                b. We will identify your usage behavior of our website through third party retargeting
                                cookies.
                            </li>
                        </ul>
                    </li>
                    <li>
                        2. How this information will be used
                        <ul>
                            <li>
                                a. We will use the data to serve you more relevant advertisements (through retargeting)
                                based on your personal preferences.
                            </li>
                            <li>
                                b. Advertisement will be related to 14th street pizza only.
                            </li>
                        </ul>
                    </li>
                    <li>
                        3. Transfer of information to third party companies.
                        <ul>
                            <li>
                                a. To help us serve you the best advertisements, we will use the tracking data in
                                collaboration with our third party advertising vendors.
                            </li>
                        </ul>
                    </li>
                    <li>
                        4. How you can delete your personal information.
                        <ul>
                            <li>
                                a. By clearing your cache you can remove your data from our tracking system.
                            </li>
                        </ul>
                    </li>
                    <li>
                        5. How you can opt-out of future communications.
                        <ul>
                            <li>
                                a. By un-subscribing from our mailing list, you can opt-out of any future communication.
                            </li>
                            <li>
                                b. By turning off your computer cache, you can opt-out of our data tracking system.
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
