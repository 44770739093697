import React, {Component, Fragment} from 'react';
import {Banner, Section} from "../../uiComponents";
import {addToCart} from "../../store/actions/Cart";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';

class Pizza extends Component {

    constructor(props) {
        super(props);
        this.state = {
            preference: 'own',
            size: {},
            flavour: {},
            sauce: {},
            vegetables: [],
            extraFlavor: [],
            extraToppings: [],
            quantity: 1
        }
    }

    clearState = () => {
        this.setState({
            size: {},
            flavour: {},
            selectedFlavours: [],
            sauce: {},
            vegetables: [],
            extraFlavor: [],
            extraToppings: [],
            quantity: 1
        })
    };

    selectPreference = (preference) => {
        this.setState({preference}, () => this.clearState());
    };

    selectVeggies = (key, selected) => {
        let selectedData = this.state[key] ? this.state[key] : [];
        if (selectedData.length) {
            let index = selectedData.indexOf(selected.vegetablename);
            if (index !== -1) {
                selectedData.splice(index, 1)
            } else {
                selectedData.push(selected.vegetablename)
            }
        } else {
            selectedData.push(selected.vegetablename)
        }
        this.setState({[key]: selectedData})
    };

    selectExtra = (key, selected) => {
        let selectedData = this.state[key] ? this.state[key] : [];
        if (selectedData.length) {
            let index = selectedData.indexOf(selected);
            if (index !== -1) {
                selectedData.splice(index, 1)
            } else {
                selectedData.push(selected)
            }
        } else {
            selectedData.push(selected)
        }
        this.setState({[key]: selectedData})
    };

    selectWithLimit = (key, selected, limit) => {
        let selectedData = this.state[key] ? this.state[key] : [];
        if (selectedData.length) {
            let index = selectedData.indexOf(selected);
            if (index !== -1) {
                selectedData.splice(index, 1)
            } else if (limit && selectedData.length < limit) {
                selectedData.push(selected)
            }
        } else {
            selectedData.push(selected)
        }
        this.setState({[key]: selectedData})
    };

    onSelect = (key, selected, type) => {
        if (key === 'flavour') {
            if (selected.flavortype === 'signature') {
                this.setState({
                    sauce: this.getFlavorSauce(selected.flavorsauces),
                    vegetables: this.getFlavorVegetables(selected.flavorvegetables)
                })
            }
        }
        this.setState({[key]: selected});

    };

    objectConvertIntoArray = (data, key, objKeyName) => {
        let updatedValue = [];
        if (data && data[key] && data[key].length) {
            data[key].map(obj => updatedValue.push(obj[objKeyName]))
        }
        return updatedValue;
    };

    renderPreference = (preference) => {
        const {size, flavour, sauce, vegetables} = this.state;
        const {location} = this.props;
        const {state} = location;
        if (preference === 'signature') {
            return (
                <Fragment>
                    {(state && state.size) ? <Section
                        title="Select Your size"
                        data={state.size}
                        titleKey='size'
                        idKey='sizeId'
                        priceKey='price'
                        servingsizeKey='servingsize'
                        selectedIdKey='sizeId'
                        imageKey='imageformobile'
                        selected={size}
                        onSelect={selected => this.onSelect('size', selected)}
                    /> : null}
                    {Object.keys(size).length && state && state.flavors[size.sizeId] ? <Section
                        title="Select Your Flavour"
                        // type='flavour-with-choices'
                        type='flavour'
                        flavour="signature"
                        data={state.flavors[size.sizeId]}
                        titleKey='flavor'
                        idKey='flavorId'
                        selectedIdKey='flavorId'
                        imageKey='flavorimage'
                        selected={flavour}
                        onSelect={selected => this.onSelect('flavour', selected)}
                    /> : null}
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    {(state && state.size) ? <Section
                        title="Select Your size"
                        data={state.size}
                        titleKey='size'
                        idKey='sizeId'
                        priceKey='price'
                        servingsizeKey='servingsize'
                        selectedIdKey='sizeId'
                        imageKey='imageformobile'
                        selected={size}
                        onSelect={selected => this.onSelect('size', selected)}
                    /> : null}
                    {Object.keys(size).length && state && state.flavors[size.sizeId] ? <Section
                        title="Select Your Flavour"
                        // type='flavour-with-choices'
                        type='flavour'
                        flavour="customize"
                        data={state.flavors[size.sizeId]}
                        titleKey='flavor'
                        idKey='flavorId'
                        selectedIdKey='flavorId'
                        imageKey='flavorimage'
                        selected={flavour}
                        onSelect={selected => this.onSelect('flavour', selected)}
                    /> : null}
                    {Object.keys(flavour).length && flavour.flavorsauces && flavour.flavorsauces.length ?
                        <Section
                            title="Select Your Sauces"
                            data={flavour.flavorsauces}
                            titleKey="saucename"
                            idKey="sauceId"
                            selectedIdKey="sauceId"
                            imageKey="sauceimageformobile"
                            selected={sauce}
                            onSelect={selected => this.onSelect('sauce', selected)}
                        /> : null}
                    {Object.keys(flavour).length && flavour.flavorvegetables && flavour.flavorvegetables.length ?
                        <Section
                            title="Select Your veggies"
                            data={flavour.flavorvegetables}
                            type="array-with-choices"
                            titleKey="vegetablename"
                            imageKey="vegetableimageformobile"
                            selected={vegetables}
                            onSelect={selected => this.selectVeggies('vegetables', selected)}
                        /> : null}
                </Fragment>
            )
        }
    };

    getPrice = (data, key, id, priceKey, data2) => {
        let price = 0;
        if (data && data[key] && data[key].length) {
            data[key].map(obj => {
                if (obj.type !== 'ExtraCheese' && data2.includes(obj[id])) {
                    price += obj[priceKey]
                }
            })
        }
        return price;
    };

    //extra cheese
    checkExtraCheese = (data) => {
        let extraCheese = '';
        if (data && data.length && data.includes('ExtraCheese')) {
            extraCheese = 'yes';
        }
        return extraCheese;
    };
    getExtraCheese = (data, key, data2) => {
        let price = 0;
        if (data && data[key] && data[key].length) {
            data[key].map(obj => {
                if (data2.includes('ExtraCheese') && obj.type === 'ExtraCheese') {
                    price += obj.price
                }
            })
        }
        return price;
    };

    //for signature
    getFlavorSauce = (sauces) => {
        let data = '';
        if (sauces && sauces.length) {
            data = sauces[0]
        } else {
            data = ''
        }
        return data;
    };
    getFlavorVegetables = (vegetables) => {
        let data = [];
        if (vegetables && vegetables.length) {
            vegetables.forEach(vegetable => data.push(vegetable.vegetablename));
        } else {
            data = ''
        }
        return data;
    };

    sumAllPrice = (payload) => {
        const {price, pizzaextrameatprice, pizzaextracheeseprice, pizzasweettoppingsprice} = payload;
        return Number(price) + Number(pizzaextrameatprice) + Number(pizzaextracheeseprice) + Number(pizzasweettoppingsprice);
    };

    //add into cart
    add = () => {
        const {quantity, size, flavour, sauce, vegetables, extraToppings, extraFlavor} = this.state;
        const {location, addToCart} = this.props;
        const {state} = location;
        let payload = {
            name : {
                pizzatype: 'pizza',
                pizzacrust: state.crust.hiddename,
                pizzameatallowed: 1,
                pizzasize: size.size,
                pizzaflavor: sauce.saucename,
                pizzaquantity: quantity,
                meattopping1: flavour.flavor,
                meattopping2: '',
                extrameattopping1: extraFlavor,
                extrameattopping2: '',
                extracheese: this.checkExtraCheese(extraToppings),
                vegetables1: vegetables,
                vegetables2: '',
                sweettoppings: extraToppings.filter(v => v !== 'ExtraCheese'),
            },
            producttype: 'pizza',
            quantity: quantity,
            price: Number(size.price),
            pizzaextrameatprice: size && size.ExtraFlavor && size.ExtraFlavor.price,
            pizzaextracheeseprice: this.getExtraCheese(size, 'ExtraAddons', extraToppings),
            pizzasweettoppingsprice: this.getPrice(size, 'ExtraAddons', 'type', 'price', extraToppings),
            total: 0,
        };
        payload.total = this.sumAllPrice(payload) * quantity;
        addToCart(payload);
        this.clearState();
    };

    increment = () => {
        this.setState({quantity: this.state.quantity + 1})
    };
    decrement = () => {
        if (this.state.quantity >= !0) this.setState({quantity: this.state.quantity - 1})
    };

    render() {
        const {preference, size, flavour, extraFlavor, extraToppings, sauce, quantity} = this.state;
        const {location} = this.props;
        const {state} = location;
        let disabled = !(Object.keys(size).length && Object.keys(flavour).length && Object.keys(sauce).length);
        let crustType = state && state.crust ? state.crust.crusttype : '';
        return (
            <Fragment>
                <div className="menu inner-body-with-no-scroll">

                    <Banner
                        style='secondary-banner'
                        image={state && state.crust ? state.crust.image : ''}
                        title={state && state.crust ? state.crust.displayname : ''}
                        content={state && state.crust ? state.crust.crustdescription : ''}
                    />

                    <div className="preference-section">

                        {crustType !== 'signature' ? <h6>choose your preference</h6> : null}

                        {crustType !== 'signature' ? <div className="preference-list">
                            <div className={`preference-list-item ${preference === 'own' ? 'active' : ''}`}
                                 onClick={() => this.selectPreference('own')}>
                                <img src='/images/create-own-pizza.png' alt="CREATE YOUR OWN PIZZA"/>
                                <span>
                            CREATE YOUR OWN PIZZA
                        </span>
                            </div>
                            <span className="or">or</span>
                            <div className={`preference-list-item ${preference === 'signature' ? 'active' : ''}`}
                                 onClick={() => this.selectPreference('signature')}>
                                <img src='/images/signature.png' alt=" CHOOSE FROM SIGNATURE SELECTIONS"/>
                                <span>
                            CHOOSE FROM SIGNATURE SELECTIONS
                        </span>
                            </div>
                        </div> : null}

                    </div>

                    {this.renderPreference(crustType === 'signature' ? 'signature' : preference)}

                    {preference === 'own' && Object.keys(size).length && size.ExtraAddons ?
                        <h4 className="title">ADD ONS!</h4> : null}
                    {preference === 'own' && Object.keys(size).length && size.ExtraFlavor && size.ExtraFlavor.choices.length ?
                        <Section
                            title="Select Your extra toppings"
                            data={size.ExtraFlavor.choices}
                            type="array-with-choices"
                            titleKey="name"
                            priceKey='price'
                            selected={extraFlavor}
                            onSelect={({name}) => this.selectWithLimit('extraFlavor', name, size.ExtraFlavor.choicesallowed)}
                        /> : null}
                    {preference === 'own' && Object.keys(size).length && size.ExtraAddons ? <Section
                        title="Select Your extra toppings"
                        data={size.ExtraAddons}
                        type="array-with-choices"
                        titleKey="type"
                        priceKey='price'
                        selected={extraToppings}
                        onSelect={({type}) => this.selectExtra('extraToppings', type)}
                    /> : null}

                </div>

                <div className="common-footer">
                    <div className="counter">
                        <button className="minus" onClick={() => this.decrement()}>
                            <MinusOutlined/>
                        </button>
                        <span className="counter-value">{quantity}</span>
                        <button className="plus" onClick={() => this.increment()}>
                            <PlusOutlined/>
                        </button>
                    </div>
                    <Button disabled={disabled} className="c-btn" onClick={() => this.add()}>
                        Add to cart
                    </Button>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ordering}) => {
    return {}
};
const mapDispatchToProps = {
    addToCart
};
export default connect(mapStateToProps, mapDispatchToProps)(Pizza);
