export default [
    {
        title: 'karachi',
        addresses: [
            {
                place: 'Khayaban-e-Bukhari',
                orderType: 'Delivery and Takeaway Only',
                timings: '24/7',
                address: 'Shop No. 1, 2 & 3, Plot No. 20-C, Bukhari Commercial Lane, No-6, Phase-VI, DHA, Karachi.'
            },
            {
                place: 'Shaheed-e-Millat',
                orderType: 'Delivery and Takeaway Only',
                timings: '24/7',
                address: `Plot No. 219 Ammar Tower Main shaheed-e-Millat Rd,
                            Opposite to Medicare hospital near UFONE Building, Karachi.`
            },
            {
                place: 'Gulshan-e-Iqbal',
                orderType: 'Delivery and Takeaway Only',
                timings: '24/7',
                address: 'AS-7 Row 1/Z Block 10A, Gulshan-e-Iqbal, Karachi.'
            }
        ]
    },
    {
        title: 'Islamabad',
        addresses: [
            {
                place: 'Blue Area',
                orderType: 'Delivery and Takeaway Only',
                timings: '10:00 am - 2:00 am',
                address: '13/14 Block 1-C, Muhammad Plaza Blue Area, Islamabad.'
            },
            {
                place: 'G11',
                orderType: 'Delivery and Takeaway Only',
                timings: '10:00 am - 2:00 am',
                address: 'Address - Abu Baker Market, Street No. 11, G-11/1, Islamabad.'
            }
        ]
    },
    {
        title: 'Multan',
        addresses: [
            {
                place: 'Gulgasht Colony',
                orderType: 'Dine In, Delivery and Takeaway',
                timings: '11:00 am - 2:00 am',
                address: '19A, Gulgasht Colony, Near Post Office, Multan.'
            }
        ]
    },
    {
        title: 'Faisalabad',
        addresses: [
            {
                place: 'Kohinoor City Road',
                orderType: 'Dine In, Delivery and Takeaway',
                timings: '11:00 am - 2:00 am',
                address: 'Basement Floor, Legacy Tower, Kohinoor City Rd. Kohinoor city, Faisalabad.'
            }
        ]
    },
    {
        title: 'Lahore',
        addresses: [
            {
                place: 'DHA Phase 5',
                orderType: 'Dine In, Delivery and Takeaway',
                timings: '11:00 am - 2:00 am',
                address: 'Plaza 140-CCA, Khayaban-e-Jinnah, DHA, Phase 5, Lahore.'
            }
        ]
    }
];
