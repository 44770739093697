import React, {Fragment} from "react";
import {Image} from "react-bootstrap";
import './ListItem.scss';
import decode from 'decode-html';

const ListItem = (props) => {
    const {
        data,
        type = '',
        onClick,
        selected = {},
        className = '',
        titleKey = 'title',
        imageKey = 'image',
        descriptionKey = 'description',
        priceKey = 'price',
        servingsizeKey = 'servingsize',
        idKey = 'id',
        selectedIdKey = 'id',
        flavour = ''
    } = props;

    if (data) {
        return (
            <ul className={`c-list ${className}`}>
                {data.map((obj, index) => {
                    if (type === 'deal' || type === 'menu') {
                        return (
                            <li key={index} className={`${selected[selectedIdKey] === obj[idKey] ? 'active' : ''}`}
                                onClick={() => onClick ? onClick(obj) : null}>
                                <Image src={obj[imageKey] ? obj[imageKey] : '/images/placeholder.png'}/>
                                <div className="list-item-content list-item-menu-content">
                                    <h4>{obj[titleKey]}</h4>
                                    {obj[descriptionKey] ?
                                        <p dangerouslySetInnerHTML={{__html: decode(obj[descriptionKey])}}/> : null}
                                    {obj[servingsizeKey] ? <p>{obj[servingsizeKey]}</p> : null}
                                    <div className="button-container">
                                        {obj[priceKey] ? <span className='price'>Rs.{obj[priceKey]}</span> : null}
                                        <button onClick={() => onClick ? onClick(obj) : null}> Order Now</button>
                                    </div>
                                </div>
                            </li>
                        )
                    } else if (type === 'normal-list') {
                        return (
                            <li key={index} className={`${selected[selectedIdKey] === obj[idKey] ? 'active' : ''}`}
                                onClick={() => onClick ? onClick(obj) : null}>
                                <Image src={obj[imageKey] ? obj[imageKey] : '/images/placeholder.png'}/>
                                <div className="list-item-content">
                                    <h4>
                                        <Image src={require('../../assets/images/crown-icon.png')}/>
                                        {obj[titleKey]}
                                    </h4>
                                    {obj[descriptionKey] ?
                                        <p dangerouslySetInnerHTML={{__html: decode(obj[descriptionKey])}}/> : null}
                                </div>
                            </li>
                        )
                    } else if (type === 'with-choices') {
                        return (
                            <li key={index} className={`${(selected) && selected.includes(obj) ? 'active' : ''}`}
                                onClick={() => onClick ? onClick(obj) : null}>
                                <Image src={obj[imageKey] ? obj[imageKey] : '/images/placeholder.png'}/>
                                <span>{obj}</span>
                            </li>
                        )
                    }else if (type === 'array-with-choices') {
                        return (
                            <li key={index} className={`${(selected) && selected.includes(obj[titleKey]) ? 'active' : ''}`}
                                onClick={() => onClick ? onClick(obj) : null}>
                                <Image src={obj[imageKey] ? obj[imageKey] : '/images/placeholder.png'}/>
                                <span>{obj[titleKey]}</span>
                                {obj[servingsizeKey] ? <span className='price'>{obj[servingsizeKey]}</span> : null}
                                {obj[priceKey] ? <span className='price'> Rs {obj[priceKey]}</span> : null}
                            </li>
                        )
                    } else if (type === 'only-text-with-choices') {
                        return (
                            <li key={index} className={`${(selected) && selected.includes(obj) ? 'active' : ''}`}
                                onClick={() => onClick ? onClick(obj) : null}>
                                <span>{obj}</span>
                            </li>
                        )
                    } else if (type === 'flavour') {
                        if (obj.flavortype === flavour) {
                            return (
                                <li key={index} className={`${selected[selectedIdKey] === obj[idKey] ? 'active' : ''}`}
                                    onClick={() => onClick ? onClick(obj) : null}>
                                    <Fragment>
                                        <Image src={obj[imageKey]}/>
                                        {obj[titleKey] ? <span>{obj[titleKey]}</span> : null}
                                    </Fragment>
                                </li>
                            )
                        }
                        return null
                    } else {
                        return (
                            <li key={index} className={`${selected[selectedIdKey] === obj[idKey] ? 'active' : ''}`}
                                onClick={() => onClick ? onClick(obj) : null}>
                                {type === 'only-image' ?
                                    <Image src={obj[imageKey]}/>
                                    : (type === 'only-text') ? <span>{obj[titleKey]}</span> :
                                        <Fragment>
                                            <Image src={obj[imageKey]}/>
                                            <span>{obj[titleKey]}</span>
                                            {obj[servingsizeKey] ? <span className='price'>{obj[servingsizeKey]}</span> : null}
                                            {obj[priceKey] ? <span className='price'> Rs {obj[priceKey]}</span> : null}
                                        </Fragment>
                                }
                            </li>
                        )
                    }
                })}
            </ul>
        )
    } else {
        return null
    }
};

export default ListItem;
