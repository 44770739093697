import React from 'react';
import {Image} from "react-bootstrap";

function AboutUs() {
    return (
        <div className="page">
            <div className="page-heading">
                <Image draggable={false} src={require('../../assets/images/headings/about-us.jpg')}/>
            </div>
            <div className="page-content">
                <p>
                    Introducing the concept 'one slice is a meal’, 14th Street Pizza is known for pioneering the 20
                    Incher pizza. Being one of the most loved pizzas in Pakistan, we have earned the love of true Pizza
                    lovers with our unending passion for creating hot, big and cheesy pizzas.
                </p>
                <p>
                    At 14th Street Pizza, you can actually make your dream pizza happen. Begin with the size you want,
                    and build on from there. With our range of sauces, meat, and veggies you can create endless
                    combinations and get just what you want. Don’t forget to add your favorite sideline and a drink for
                    a perfect meal.
                </p>
                <p>
                    Here you can become a part of our world, enjoy and share it with your friends.
                </p>
            </div>
        </div>
    );
}

export default AboutUs;
