import {GET_PIZZA_ORDERING_API, GET_QUICK_PIZZA_ORDERING_API} from "../constants/Ordering";
import {get} from "../helper";
const token = '64ad36dd7e876ea29fa5e221d724fe5df8948216';

export const getQuickPizzaOrdering = () => (dispatch) => {

    dispatch({type: GET_QUICK_PIZZA_ORDERING_API, loading: true});
    get('quickpizzaordering',{ headers: {
        "Authorization" : `Bearer ${token}`,
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS,HEAD',
        "Access-Control-Allow-Credentials": "true" ,
        'Access-Control-Max-Age': '60',
        'Access-Control-Allow-Origin': '*'
    } })
        .then(({data}) => {

            if (data) {
                dispatch({type: GET_QUICK_PIZZA_ORDERING_API, payload: data, loading: false});
            } else {
                dispatch({type: GET_QUICK_PIZZA_ORDERING_API, loading: false});
            }

        })
        .catch((error) => {

            dispatch({type: GET_QUICK_PIZZA_ORDERING_API, loading: false});
        });
};
export const getPizzaOrdering = () => (dispatch) => {

    dispatch({type: GET_PIZZA_ORDERING_API, loading: true});
    get('pizzaordering',{ headers: {
        "Authorization" : `Bearer ${token}`,
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS,HEAD',
        "Access-Control-Allow-Credentials": "true" ,
        'Access-Control-Max-Age': '60',
        'Access-Control-Allow-Origin': '*'
    } })
        .then(({data}) => {

            if (data) {
                dispatch({type: GET_PIZZA_ORDERING_API, payload: data, loading: false});
            } else {
                dispatch({type: GET_PIZZA_ORDERING_API, loading: false});
            }

        })
        .catch((error) => {

            dispatch({type: GET_PIZZA_ORDERING_API, loading: false});
        });
};