import React from "react";

const emailRule = [
    {
        type: 'email',
        message: 'The input is not valid Email!',
    },
    {
        required: true,
        message: 'Please input your Email!',
    },
];
const numberRule = [
    {
        required: true,
        message: 'Please enter phone number!',
    },
];
const nameRule = [
    {
        required: true,
        message: 'Please enter name!',
    },
];
const promoCodeRule = [
    {
        required: true,
        message: 'Please enter valid promo code!',
    },
];
const passwordRule = [
    {
        required: true,
        message: 'Please enter password!',
    },
];
const cityRule = [
    {
        required: true,
        message: 'Please enter your city!',
    },
];
const countryRule = [
    {
        required: true,
        message: 'Please enter your country!',
    },
];
const hearAboutRule = [
    {
        required: true,
        message: 'Please select where did you hear about us?',
    },
];
const postalAddressRule = [
    {
        required: true,
        message: 'Please enter postal address?',
    },
];
const messageRule = [
    {
        required: true,
        message: 'Please enter message?',
    },
];
const selectCityRule = [
    {
        required: true,
        message: 'Please select your city?',
    },
];
const deliveryAddressRule = [
    {
        required: true,
        message: 'Please enter your delivery address?',
    },
];
const selectPaymentMethodRule = [
    {
        required: true,
        message: 'Please select payment method?',
    },
];
const selectedAreaRule = [
    {
        required: true,
        message: 'Please select area?',
    }
];
const selectedSubAreaRule = [
    {
        required: true,
        message: 'Please select Sub area?',
    }
];

export {
    emailRule,
    numberRule,
    nameRule,
    passwordRule,
    cityRule,
    countryRule,
    hearAboutRule,
    postalAddressRule,
    messageRule,
    selectCityRule,
    deliveryAddressRule,
    selectPaymentMethodRule,
    promoCodeRule,
    selectedAreaRule,
    selectedSubAreaRule
}
