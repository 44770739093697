import React from 'react';
import {Image} from "react-bootstrap";

function ContactUs() {
    return (
        <div className="page">
            <div className="page-heading">
                <Image draggable={false} src={require('../../assets/images/headings/contact-us.jpg')}/>
            </div>
            <div className="page-content">
                <ul className="contact-list">
                    <li>
                        <a href="tel:021111363636">
                            <div className="icon">
                                <Image src={require('../../assets/images/phone-icon.png')}/>
                            </div>
                            <span>021-111-36-36-36</span>
                        </a>
                    </li>
                    <li>
                        <a href="mailto:info@14thstreetpizza.com">
                            <div className="icon">
                                <Image src={require('../../assets/images/email-icon.png')}/>
                            </div>
                            <span>info@14thstreetpizza.com</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/14thstreetpizza" target="_blank">
                            <div className="icon">
                                <Image src={require('../../assets/images/facebook-icon.png')}/>
                            </div>
                            <span>www.facebook.com/14thstreetpizza</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.twitter.com/14thstreetpizza" target="_blank">
                            <div className="icon">
                                <Image src={require('../../assets/images/twitter-icon.png')}/>
                            </div>
                            <span>www.twitter.com/14thstreetpizza</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/14thstreetpizzaco" target="_blank">
                            <div className="icon">
                                <Image src={require('../../assets/images/instagram-icon.png')}/>
                            </div>
                            <span>www.instagram.com/14thstreetpizzaco</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default ContactUs;
