import React from 'react';
import {Image} from "react-bootstrap";
import {Form, Input} from "antd";
import {cityRule, emailRule, nameRule, numberRule, messageRule} from "../../rules";
import {connect} from "react-redux";
import {submitFeedback} from "../../store/actions/More";
import {Loading} from "../../uiComponents";

const CForm = ({loading, submit}) => {

    const [form] = Form.useForm();

    const callback = () => {
        form.resetFields();
    };

    const onFinish = values => {
        let formdata = new FormData();
        formdata.append("name", values.name);
        formdata.append("email", values.email);
        formdata.append('phone', values.phone);
        formdata.append('city', values.city);
        formdata.append('message', values.message);
        submit(formdata, callback);
    };

    return (
        <Form name="f-form" form={form}  initialValues={{}} onFinish={onFinish}>
            <Form.Item label="Name" name="name" rules={nameRule}>
                <Input/>
            </Form.Item>
            <Form.Item label="Email" name="email" rules={emailRule}>
                <Input/>
            </Form.Item>
            <Form.Item label="Phone" name="phone" rules={numberRule}>
                <Input type="number" />
            </Form.Item>
            <Form.Item label="City" name="city" rules={cityRule}>
                <Input/>
            </Form.Item>
            <Form.Item label="Message" name="message" rules={messageRule}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item>
                <div className="button-container">
                    <button disabled={false}
                            className="c-btn"
                            type="primary" htmlType="submit">
                        Submit
                    </button>
                </div>
            </Form.Item>
        </Form>
    );
};

function Feedback({loading, submitFeedback}) {
    return (
        <div className="page">
            {loading ? <Loading /> : null}
            <div className="page-heading">
                <Image draggable={false} src={require('../../assets/images/headings/feedback.jpg')}/>
            </div>
            <div className="page-content">
                <div className="form">
                    <CForm
                        loading={loading}
                        submit={submitFeedback}
                    />
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = ({more}) => {
    return {
        loading: more.loading
    }
};
const mapDispatchToProps = {
    submitFeedback
};
export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
