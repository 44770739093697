import React from 'react';

function Alert({text}) {
    return (
        <div className="custom-alert">
            <p>{text}</p>
        </div>
    );
}

export default Alert;
